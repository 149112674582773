.Dialogmodal .MuiDialogContent-dividers {
  border-bottom: 0px !important;
  border-top: 0px !important;
  /* overflow: hidden; */
}

.Dialogmodal h6 {
  color: #333;
  /* font-family: titillium web !important; */
}

.modaldiv .cancelbtn {
  border-radius: 0;
  background-color: #f1221a;
  color: white;
  box-shadow: 0;
}

.Dialogmodal .MuiDialogTitle-root {
  box-shadow: 0px 0px 3.5px 0px #ccc;
  border-bottom: 1px solid #ccc;
  background-color: #510F30;

  /* display: none; */
}

.Dialogmodal .MuiIconButton-root:hover {
  background-color: transparent;
}

.Dialogmodal .closebtn {
  background-color: rgb(201, 201, 201);
  border-radius: 0px;
  color: rgb(255, 255, 255);
  text-transform: capitalize;
  /* padding: 9px; */
  margin-right: 10px;
  color: #000;
  font-size: 13px;
  width: 90px;
}

.Dialogmodal .createbtn {
  background-color: #2580eb;
  border-radius: 0px;
  color: rgb(255, 255, 255);
  text-transform: capitalize;
  /* padding: 9px; */
  margin-right: 10px;
  color: #fff;
  font-size: 13px;
  width: 90px;
}

.Dialogmodal .createbtn:hover {
  background-color: #2580eb;
}

.customcloseicon:hover {
  border-radius: 25px !important;
  background-color: #bbb7b7 !important;
  color: black !important;
}

.customcloseicon:focus {
  outline: none;
}

.closemodal_icon,
.customcloseicon {
  fill:#ff0068 !important;
  height: 25px !important;
  width: 25px !important;
  margin-top: 13px !important;
  cursor: pointer;
  right: 15px !important;
}

.Dialogmodal h6 {
  font-size: 18px !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  /* font-family: titillium web !important; */
}

.Dialogmodal h6 span {
  /* font-family: titillium web !important; */
  font-size: 22px;
}

.delete_media_title_container {
  padding: 10px 10px 10px 10px;
}

.common_delete_div .detete_no {
  background-color: rgb(201, 201, 201) !important;
  color: #333;
  font-size: 16px !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
  font-weight: 500;
  min-width: 90px;
}

.common_delete_div .detete_yes {
  background-color: var(--app-theme) !important;
  color: #fff !important;
  font-size: 16px !important;
  margin-left: 15px !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
  font-weight: 500;
  min-width: 90px;
}

.manage_package_delete_popup .MuiDialog-paperFullWidth,
.working_hours_delete_popup .MuiDialog-paperFullWidth {
  min-width: 25% !important;
  max-width: 30% !important;
  width: auto !important;
}