/* .input_span {
  display: block !important;
  margin-bottom: 5px !important;
}

.input_field_service {
  margin-bottom: 20px !important;
  width: 100% !important;
}

.input_slot_durations {
  display: flex;
  margin-bottom: 20px !important;
}

.input_spans {
  padding: 15px 20px;
}

.input_row_field {
  margin: 25px 0px 0px 0px
}

.dropdown_add_service {
  background: #fff;
  width: 100% !important;
  padding: 16px 0px;
  margin-bottom: 20px;
  color: #000;
  outline: #000;
  border: 1px solid #bababa;
}

.dropdown_add_service:hover {
  background: #fff;
  color: #000;
  border: 1px solid #bababa;
}

.dropdown-item:active {
  background-color: none !important;
}

.btn.show {
  background: #fff;
  color: #000;
  border: 1px solid #bababa;
}

.input_manage_service .dropdown-menu.show {
  width: 100%;
}

.toggle_manage_service_add {
  padding-left: 50px;
  display: flex;
}

.input_manage_service {
  padding: 0px 50px;
}

.dropdown-toggle:empty::after {
  margin-left: 600px;
}

.card_row {
  background: #f4f4f4 !important;
  padding: 6px 6px 5px;
  margin-bottom: 5px;
}

.card_row_img {
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.image_body_size {
  height: 75px;
  width: 75px;
  border-radius: 50%;
}

.image_body_sizes {
  height: 80px;
  width: 80px;
}

.success_ok_btn {
  background: var(--app-theme) !important;
  color: #fff !important;
  padding: 5px 30px;
  outline: none;
  border: none;
  border-radius: 15px;
}

.success_ok_btn:hover {
  color: #fff !important;
}

.success_body_size {
  padding-top: 35px;
  color: var(--app-theme);
}

.success_msg {
  font-size: 15px;
  color: #000 !important;
}

.upload_service i {
  top: 56px;
  right: 20px;
  position: absolute;
}

.upload_service {
  position: relative;
}

.file_upload_ {
  display: flex;
}

.Uploading_box {
  position: absolute;
  opacity: 0;
}

.input_box {
  width: 100%;
  padding: 13px 10px;
}

.file_select_report {
  width: 100%;
}

.eportcontainer {
  display: flex;
}

.enter_detalis input {
  padding: 6px 5px;
  width: 608px;
  height: 42px;
  border-radius: 5px;
  position: relative;
}

.enter_detalis label {
  padding: 10px 0;
}

.upload_image_icon {
  position: absolute;
  right: 30px;
  top: 42px;
  color: #000;
  height: 25px;
}

.input_slot_durations .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  text-align: center !important;
}

.add_button.MuiButtonBase-root {
  background-color: var(--app-theme) !important;
  width: 90px;
  height: 45px;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  font-family: 'Roboto-Regular';
}

.content_view .css-191o5bo-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  margin: 0 !important;
}

.content_view .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  height: 40px !important;
}

.input_add_service {
  margin-bottom: 19px;
}

.addmanager-post {
  width: 70%;
  margin: 0 auto;
}

.service_card_detials {
  height: fit-content;

  border: var(--app-theme) 2px solid;
  margin: 0px 0px 0px 0px;
}

.service_image_display {

  width: 100%;
  height: 170px;
  padding: 10px;
  border-radius: 15px;
  margin: 5px 0px 0px 5px;
}

.add_button_grid {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.service_content {
  padding: 30px 0px 0px 0px;
}

.service_title_card {
  font-size: 22px;
  color: #510f30;
  font-weight: 500;
}

.sertvice_description {
  font-size: 20px;
  color: #000;
}


.progressBar {
  flex-grow: 1;
}

.upload-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.progress-text {

}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  height: 300px;
  width: 100px;
  text-align: center;
}

.css-1dmzujt {
  height: 300px;
  width: 100px;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.every_days_service {
  margin-top: 13px;
  margin-left: -24px !important;
}

@media (min-width:1100px) and (max-width:1200px){
  .content_view {
    height: calc(100vh - 170px) !important;
  }
  .service_content {
    padding: 30px 0px 0px 0px;
}
}

@media (min-width:900px) and (max-width:1100px){
  .content_view {
    height: calc(100vh - 175px) !important;
  }
  .service_content {
    padding: 30px 0px 0px 0px;
  }
}
.css-ke5b6m-MuiButtonBase-root-MuiButton-root.Mui-disabled{
  color: #fff !important;
} */

/* 


:root {
  --app-theme: #2F887C;
 
}
.input_span {
  display: block !important;
  margin-bottom: 5px !important;
}

.input_field_service {
  margin-bottom: 20px !important;
  width: 100% !important;
}

.input_slot_durations {
  display: flex;
  margin-bottom: 20px !important;
}

.input_spans {
  padding: 15px 20px;
}

.input_row_field {
  margin: 25px 0px 0px 0px
}

.dropdown_add_service {
  background: #fff;
  width: 100% !important;
  padding: 16px 0px;
  margin-bottom: 20px;
  color: #000;
  outline: #000;
  border: 1px solid #bababa;
}

.dropdown_add_service:hover {
  background: #fff;
  color: #000;
  border: 1px solid #bababa;
}

.dropdown-item:active {
  background-color: none !important;
}

.btn.show {
  background: #fff;
  color: #000;
  border: 1px solid #bababa;
}

.input_manage_service .dropdown-menu.show {
  width: 100%;
}

.toggle_manage_service_add {
  padding-left: 50px;
  display: flex;
}

.input_manage_service {
  padding: 0px 50px;
}

.dropdown-toggle:empty::after {
  margin-left: 600px;
}

.card_row {
  background: #f4f4f4 !important;
  padding: 6px 6px 5px;
  margin-bottom: 5px;
}

.card_row_img {
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.image_body_size {
  height: 75px;
  width: 75px;
  border-radius: 50%;
}

.image_body_sizes {
  height: 80px;
  width: 80px;
}

.success_ok_btn {
  background: var(--app-theme) !important;
  color: #fff !important;
  padding: 5px 30px;
  outline: none;
  border: none;
  border-radius: 15px;
}

.success_ok_btn:hover {
  color: #fff !important;
}

.success_body_size {
  padding-top: 10px;
  color: var(--app-theme);
}

.success_msg {
  font-size: 15px;
}

.upload_service i {
  top: 56px;
  right: 20px;
  position: absolute;
}

.upload_service {
  position: relative;
}

.file_upload_ {
  display: flex;
}

.Uploading_box {
  position: absolute;
  opacity: 0;
}

.input_box {
  width: 100%;
  padding: 13px 10px;
}

.file_select_report {
  width: 100%;
}

.eportcontainer {
  display: flex;
}

.enter_detalis input {
  padding: 6px 5px;
  width: 608px;
  height: 42px;
  border-radius: 5px;
  position: relative;
}

.enter_detalis label {
  padding: 10px 0;
}

.upload_image_icon {
  position: absolute;
  right: 30px;
  top: 42px;
  color: #000;
  height: 25px;
}

.input_slot_durations .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  text-align: center !important;
}

.add_button.MuiButtonBase-root {
  background-color: var(--app-theme) !important;
  width: 90px;
  height: 45px;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  font-family: 'Roboto-Regular';
}

.cnl_button.MuiButtonBase-root {
  background-color: #FF0068 !important;
  width: 90px;
  height: 45px;
  border-radius: 23px;
  display: flex;
  justify-content: center;
}

.content_view .css-191o5bo-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  margin: 0 !important;
}

.content_view .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  height: 40px !important;
}

.input_add_service {
  margin-bottom: 19px;
}

.service_card_detials {
  height: fit-content;
  border: var(--app-theme) 2px solid;
  margin: 0px 0px 0px 0px;
}
.service_image_display {
  width: 100%;
  height: 170px;
  padding: 10px;
  border-radius: 15px;
  margin: 5px 0px 0px 5px;
}

.service_card_detialsDIv1 {
  display: flex;
  align-items: center;
}

.add_button_grid {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
}

.service_content {
  padding: 30px 0px 0px 0px;
}

.service_title_card {
  font-size: 22px;
  color: #510f30;
  font-weight: 500;
}

.sertvice_description {
  font-size: 20px;
  color: #000;
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  height: 300px;
  width: 100px;
  text-align: center;
}

.css-1dmzujt {
  height: 300px;
  width: 100px;
  text-align: center;
}

.upload-container {
  display: flex;
  justify-content: space-evenly;
}

.progress-text {
  margin-top: -11px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.every_days_service {
  margin-top: 13px;
  margin-left: 1px !important;
}
.css-ke5b6m-MuiButtonBase-root-MuiButton-root.Mui-disabled{
  color: #fff !important;
}
.addmanager-post {
  width: 70%;
  margin: 0 auto;
}


.add_service_details{
  width: 100%;
  border: 1px solid black;
  padding: 0px 37px 0px 37px;
  border-color: #2F887C47;
  box-shadow: 0px 0px 6px -3px;
}
.fee_slot_duration{
  display: flex;
    justify-content: space-between;
    align-items: center;
}
.manage_service_adding_details{
  display: flex;

}
.speciality_details_new{
  padding-bottom: 35px;
  padding-top: 35px;
}
.manage_service_add_button{
  border: 1px solid black;
  background-color : red;
  color:white;

}
.save_details_new{
  display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 17px 0px !important;
}
.save_button_details{
  border: 1px solid red;
    padding: 6px 33px 6px 33px;
    border-radius: 25px;
    border-color: var(--app-theme);
    background-color: var(--app-theme);
    color: white;
    font-size: 16px;
}
.save_profile_details{
  display: flex;
    justify-content: center;
    align-items: center;
    padding: 33px;
}
.speciality_name_details{
  color:var(--app-theme) ;
  font-weight: 600 !important;

}
.speciality_ame_aded{
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.speciality_ame_aded_active{
  margin-top: 16px !important;
  margin-bottom: 0px !important;
}
.speciality_ame_aded_fee{
  margin : 0;
}
.demo_special_name{
  color: var(--app-theme) !important;
  font-weight: 600 !important;
}

.dropdowntext{
  margin-left: 10px;
} */

:root {
  --app-theme: #2f887c;
  /* Define a root variable for primary color */
  /* #83af40 Old color code */
}

.input_span {
  display: block !important;
  margin-bottom: 5px !important;
}

.input_field_service {
  margin-bottom: 20px !important;
  width: 100% !important;
}

.input_slot_durations {
  display: flex;
  margin-bottom: 20px !important;
}

.input_spans {
  padding: 15px 20px;
}

.input_row_field {
  margin: 25px 0px 0px 0px;
}

.dropdown_add_service {
  background: #fff;
  width: 100% !important;
  padding: 16px 0px;
  margin-bottom: 20px;
  color: #000;
  outline: #000;
  border: 1px solid #bababa;
}

.dropdown_add_service:hover {
  background: #fff;
  color: #000;
  border: 1px solid #bababa;
}

.dropdown-item:active {
  background-color: none !important;
}

.btn.show {
  background: #fff;
  color: #000;
  border: 1px solid #bababa;
}

.input_manage_service .dropdown-menu.show {
  width: 100%;
}

.toggle_manage_service_add {
  padding-left: 50px;
  display: flex;
}

.input_manage_service {
  padding: 0px 50px;
}

.dropdown-toggle:empty::after {
  margin-left: 600px;
}

.card_row {
  background: #f4f4f4 !important;
  padding: 6px 6px 5px;
  margin-bottom: 5px;
}

.card_row_img {
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.image_body_size {
  height: 75px;
  width: 75px;
  border-radius: 50%;
}

.image_body_sizes {
  height: 80px;
  width: 80px;
}

.success_ok_btn {
  background: var(--app-theme) !important;
  color: #fff !important;
  padding: 5px 30px;
  outline: none;
  border: none;
  border-radius: 15px;
}

.success_ok_btn:hover {
  color: #fff !important;
}

.success_body_size {
  padding-top: 10px;
  color: var(--app-theme);
}

.success_msg {
  font-size: 15px;
}

.upload_service i {
  top: 56px;
  right: 20px;
  position: absolute;
}

.upload_service {
  position: relative;
}

.file_upload_ {
  display: flex;
}

.Uploading_box {
  position: absolute;
  opacity: 0;
}

.input_box {
  width: 100%;
  padding: 13px 10px;
}

.file_select_report {
  width: 100%;
}

.eportcontainer {
  display: flex;
}

.enter_detalis input {
  padding: 6px 5px;
  width: 608px;
  height: 42px;
  border-radius: 5px;
  position: relative;
}

.enter_detalis label {
  padding: 10px 0;
}

.upload_image_icon {
  position: absolute;
  right: 30px;
  top: 42px;
  color: #000;
  height: 25px;
}

.input_slot_durations .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  text-align: center !important;
}

.add_button.MuiButtonBase-root {
  background-color: var(--app-theme) !important;
  width: 90px;
  height: 45px;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  font-family: "Roboto-Regular";
}

.cnl_button.MuiButtonBase-root {
  background-color: #ff0068 !important;
  width: 90px;
  height: 45px;
  border-radius: 23px;
  display: flex;
  justify-content: center;
}

.content_view .css-191o5bo-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  margin: 0 !important;
}

.content_view .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  height: 40px !important;
}

.input_add_service {
  margin-bottom: 19px;
}

.service_card_detials {
  height: fit-content;
  border: var(--app-theme) 2px solid;
  margin: 0px 0px 0px 0px;
}

.service_image_display {
  width: 100%;
  height: 170px;
  padding: 10px;
  border-radius: 15px;
  margin: 5px 0px 0px 5px;
}

.service_card_detialsDIv1 {
  display: flex;
  align-items: center;
}

.add_button_grid {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
}

.service_content {
  padding: 30px 0px 0px 0px;
}

.service_title_card {
  font-size: 22px;
  color: #510f30;
  font-weight: 500;
}

.sertvice_description {
  font-size: 20px;
  color: #000;
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  height: 300px;
  width: 100px;
  text-align: center;
}

.css-1dmzujt {
  height: 300px;
  width: 100px;
  text-align: center;
}

.upload-container {
  display: flex;
  justify-content: space-evenly;
}

.progress-text {
  margin-top: -11px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.every_days_service {
  margin-top: 13px;
  /* margin-left: -2px !important; */
  margin-left: 1px !important;
}

.css-ke5b6m-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: #fff !important;
}

.addmanager-post {
  width: 70%;
  margin: 0 auto;
}

.add_service_details {
  /* width: 100%; */
  border: 1px solid black;
  padding: 0px 37px 0px 37px;
  border-color: #2f887c47;
  box-shadow: 0px 0px 6px -3px;
  border-radius: 20px;
}

.Text_Add_field {
  height: 42px !important;
  margin: 8px 0px !important;
}

.fee_slot_duration {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.manage_service_adding_details {
  display: flex;
  justify-content: center;
}

.speciality_details_new {
  padding-bottom: 35px;
  padding-top: 35px;
}

.manage_service_add_button {
  border: 1px solid black;
  background-color: red;
  color: white;
}

.save_details_new {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 17px 0px !important;
}

.save_button_details {
  border: 1px solid red;
  padding: 6px 33px 6px 33px;
  border-radius: 25px;
  border-color: var(--app-theme);
  background-color: var(--app-theme);
  color: white;
  font-size: 16px;
}

.save_profile_details {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 33px;
}

.speciality_name_details {
  color: var(--app-theme);
  font-weight: 600 !important;
}

.speciality_ame_aded {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.speciality_ame_aded_active {
  margin-top: 16px !important;
  margin-bottom: 0px !important;
}

.speciality_ame_aded_fee {
  margin: 0;
}

.demo_special_name {
  color: var(--app-theme) !important;
  font-weight: 600 !important;
}

.dropdowntext {
  margin-left: 10px;
}

.fontSize10 {
  font-size: 10px;
}