.support_content_card {
    margin-top: 15px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px !important;
    padding: 10px 20px;
}

.support_add_circle {
    margin-top: 3px;
    /* width: 50px;
    height: 50px; */
    width: 40px;
    height: 40px;
    background-color: var(--app-theme);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
}

.support_card p {
    margin: 0;
}

.support_card_date {
    margin-left: 164px !important;
}

.support_card_name {
    display: flex;
    padding-right: 15px;
    padding-bottom: 10px;
    margin-top: 6px;
}

.support_card_name .title {
    width: 150px;
    color: #707070;
}

.support_card_value {
    padding-bottom: 10px;
}

.support_open {
    color: #F45959;
}

.support_icons {
    display: flex;
    margin-top: 5px;
    justify-content: space-evenly;
    width: 300px;
    height: 30px
}

.support_play_icons {
    color: var(--app-theme);
    font-size: 20px;
    padding: 10px;
}

.support_eye_icons {
    color: #5377EE;
    font-size: 20px;
    padding: 10px;
}

.remarks_close_btn {
    text-align: end;
}

.remarks_close {
    background-color: var(--app-theme) !important;
    padding: 7px 20px !important;
    color: #fff !important;
    text-transform: capitalize !important;
    border-radius: 23px !important;
    border: none;
    outline: none;
}

.step_model {
    display: flex;
}

.remarks_stepper .MuiSvgIcon-fontSizeMedium {
    color: var(--app-theme) !important;
}

.remarks_chat {
    padding: 20px 40px;
}

.eye {
    height: 25px;
    width: 25px;
    margin: 0px 0px 20px 46px;
    cursor: pointer;

}

.play {
    height: 20px;
    width: 16px;
    margin: 0px 0px 0px 145px;
    cursor: pointer;
}

.icons_end {
    display: grid;
    justify-content: end;
}

.audio_tag {
    visibility: hidden;
    height: 17px;
    width: 25px;
    margin: 5px;
}

.close_complaint {
    background: var(--app-theme) !important;
    border-radius: 23px !important;
    margin: 10px 0px 0px 137px !important;
    color: #fff;
    padding: 8px 40px !important;
    text-transform: capitalize !important;
}

.reopen_complaint {
    background: #ff0068 !important;
    border-radius: 23px !important;
    margin: 10px 0px 0px 100px !important;
    color: #fff !important;
    /* padding: 8px 40px !important; */
    height: 38px;
    width: 186px;
    text-transform: capitalize !important;
}

.reopen__complaint {
    margin-left: 62px;
    color: "#F45959";
    background-color: "white"
}

.Reopen_btn {
    color: white !important;
    text-transform: capitalize !important;
    background: #FF0068 !important;
    border-radius: 30px !important;
    padding: 8px 40px !important;
    margin-left: 30px !important;
    height: 42px !important;
    margin-top: 9px !important;
}

.support_card_date_reopen {
    margin-left: 240px !important;
}

.support_icons_reopen {
    display: flex;
    margin-left: 80px;
    margin-top: 5px;
    justify-content: space-evenly;
    width: 300px;
    height: 30px
}

.Remark_Content {
    width: 60%;
}

.view_imag {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.view_post_cls {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    width: 100%;
    height: 100%;
}
.video_cls_width{
    width: 100%;
    height: 100%;
    object-fit: fill;
}