.deal_card {
    border: 3px dashed var(--app-theme);
    padding: 25px 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 12px #00000027;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin-bottom: 15px;
}

.deal_card .deal_header {
    font-size: 16px;
    color: #000000;
    opacity: 100%;
    font-weight: 600;
    margin-bottom: 9px;
}

.error_text {
    color: #ff0068;
    font-size: 12px;
}

.deal_card .deal_data {
    font-size: 16px;
    color: #707070;
    opacity: 100%;
}

.deal_off_img {
    width: fit-content;
    position: relative;
}

.deal_off_img div {
    position: absolute;
    left: 6px;
    font-size: 11px;
    top: 28px;
    color: #ffffff;
    width: 70px;
    text-align: center;
}

.pos_rel {
    position: relative;
}

.deal_edit {
    position: absolute;
    top: 26px;
    right: 0px;
    cursor: pointer;
}

.dialog_header {
    display: flex;
    /* margin: 0px 20px; */
    padding: 10px;
    border-bottom: 1px solid #000;
    background-color: var(--app-theme);
    color: white;
}

.dialog_header .dialog_title {
    color: white;
    font-size: 18px;
    font-family: TitilliumWeb-Regular;
}

.dialog_header img {
    width: 15px;
    cursor: pointer;
}

.deal_option_group {
    display: flex;
}

.deal_strick {
    /* position: absolute;
    top: 22px;
    left: 75px;
    color: #707070; */
    position: absolute;
    top: 50%;
    left: 100px;
    color: #707070;
    transform: translate(0%, -50%);
}

/* success model design start */
.success_model_body .icon {
    width: fit-content;
    margin: 15px auto;
}

.success_model_body .icon img {
    /* width: 100px; */
    width: 80px;
}

.success_model_body .title {
    color: var(--app-theme);
    /* font-size: 17px; */
    font-family: 'TitilliumWeb-Regular' !important;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.success_body_size_alert {
    color: #ff0068;
    /* font-size: 17px; */
    font-family: 'TitilliumWeb-Regular' !important;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 15px;
    margin-top: 30px;
}

.success_model_body .content {
    color: #000;
    font-family: Roboto-Regular !important;
    /* font-size: 14px; */
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 15px;
}

.add_modol {
    border-radius: 25px !important;
}

.css-twia2z-MuiPaper-root-MuiDialog-paper {
    border-radius: 20px !important;
}

.MuiDialog-paper {
    border-radius: 20px !important;
}

.css-78kaln-MuiInputBase-root-MuiOutlinedInput-root {
    margin: 8px 8px 8px 18px !important;
    height: 25px !important;
    width: 125px !important;
}

/* success model design end */

.input121 {
    border: none !important;
    padding: 0px;
    margin-left: 110px;
    height: 10px;
    margin-top: -20px;
    width: 18px;
    outline: none !important;
    background: none;
    position: absolute !important;
    top: 0;
    right: 0;
}

.input121 .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important;
    outline: none !important;
}

.input121 .MuiOutlinedInput-notchedOutline .css-igs3ac {
    border: none !important;
    outline: none !important;
}

.deal_active_label {
    margin: 0px 0px 6px 0px;
}
.kwd-font{
    font-size: 10px;
}