.Re {
    font-size: 14px;
    color: var(--app-theme);
    font-family: Roboto-Regular;
}

.reportimg {
    height: 64vh;
    width: 49vh;
}

.viewReferral_Details {
    display: flex;
    justify-content: space-between;
}

.view_referral {
    width: 50%;
    height: 100px;
    padding: 18px;
    margin-top: 90px;
}

.viewreferraldoctorname {
    font-size: 17px;
    font-weight: 400;
    color: #510F30 !important;
    /* margin-bottom: 0px !important; */
}

.viewreferraldoctorpatientname {
    font-size: 14px;
    color: #6c757d;
    font-weight: 400;
    /* margin-bottom: 0px !important; */
}

.referraldoctorpatientname_remarks {
    margin-top: 25px;
    color: #510F30;
}

.referral_doctor_Image {
    width: 50%;
    height: 100%;
    margin-top: 80px;
    /* display: flex; */
    display: block;
    justify-content: center;
}

.referral_Image {
    /* width: 480px;
    height: 500px; */
    width: 100%;
    height: 90%;
}
.image-port{
    height: 415px;
}

.referral_Remarks_Details {
    border: 1px solid #6c757d;
    height: auto;
    padding: 10px;
    border-radius: 5px;
}

.viewreferraladdress {
    font-size: 14px;
    color: #6c757d;
    font-weight: 400;
}