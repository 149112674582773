.row_content {
    margin: 0%;
}

.header_column {
    display: flex;
    margin: 25px;
}

.upload-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

input.input_Getstatus {
    width: 40px;
    height: 20px;
    align-items: center;
}

.page_head_date_advertisement {
    margin: 6px 0px 0px 0px;
}

.date_left_button,
.date_right_button {
    background-color: #D4C3CC !important;
    color: var(--app-theme) !important;
    margin: 0 10px !important;
}

.date_button {
    background-color: var(--app-theme) !important;
    color: #ffffff !important;
}

.date_left_button_icon,
.date_right_button_icon {
    padding: 5px 0 !important;
}

.advertisement_list_card {
    height: 170px;
    margin: 45px 0px 10px 0px;
    border: 2px dashed #707070;
    box-shadow: none !important;
}

.status_content {
    height: 53px;
    margin: 0px;
    padding: 0px 25px 0px 25px;
}

.status_result {
    margin: 25px 0px 0px 0px;
    padding: 0px;
}

.list_headings {
    font-size: 14px;
    color: #000000;
    text-align: left;
    margin: 25px 0px 0px 15px;
}

.list_content {
    font-size: 12px;
    color: grey;
    text-align: center;
    margin: 13px 30px;
}

.list_content_datae {
    font-size: 12px;
    color: grey;
    text-align: center;
    margin: 13px 15px;
}

.phone_image {
    height: 50px;
    width: 26px;
    margin: 11px 30px;
}

.advertisementimage {
    height: 60px;
    width: 94px;
    /* margin: 10px 15px; */
    margin: 10px -4px;
    border-style: solid;
    border-color: var(--app-theme);
    padding: 0px;
    object-fit: cover;
}

.advertisement_stepper.Mui-disabled svg,
.Mui-active svg {
    border: 1px solid grey !important;
    border-radius: 100%;
    height: 40px !important;
    width: 40px !important;
    margin-top: -7px;
}

.advertisement_stepper .Mui-disabled circle,
.Mui-active circle {
    fill: white;
}



.advertisement_stepper .MuiStepLabel-alternativeLabel {
    margin: 0px !important;
    font-size: 8px !important;
    color: #333333 !important;

    
}

.advertisement_stepper .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: var(--app-theme) !important;
    height: 19px !important;
    width: 19px !important;
}

.advertisement_stepper .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: var(--app-theme) !important;
    height: 19px !important;
    width: 19px !important;
}

.advertisement_stepper .css-vnkopk-MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    color: #E3E3E3 !important;
    height: 19px !important;
    width: 19px !important;
}

.advertisement_stepper .css-95m0ql-MuiStepConnector-line {
    display: block;
    border-color: #510F30 !important;
    border-top-style: solid;
    border-top-width: 3px !important;
}

.advertisement_stepper .css-z7uhs0-MuiStepConnector-line {
    display: block;
    border-color: #510F30 !important;
    border-top-style: solid;
    border-top-width: 3px !important;
}

.advertisement_stepper .MuiStepConnector-line {
    display: block;
    border-color: #510F30 !important;
    border-top-style: solid;
    border-top-width: 3px !important;
    margin-top: -4px !important;
}

.advertisement_stepper .css-vnkopk-MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    /* color: #E3E3E3 !important; */
    color: var(--app-theme) !important;
    height: 19px !important;
    width: 19px !important;
}

/* .advertisement_stepper .MuiSvgIcon-fontSizeMedium {
    color: none !important
} */

.status_date {
    margin: 0px;
    padding: 0px;
    font-size: 7px;
    position: relative;
    /* left: 95px; */
    left: calc(7.5vw - -4px);
    color: grey;
    bottom: 65px;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    height: 19px !important;
}

.body_content {
    padding-top: 80px;
    padding-bottom: 110px;

}

.status_conten_full {
    padding: 0px 55px 0px 60px;
}

.page_head_appoinment {
    margin: 2px 0px 2px 12px;
    font-size: 18px;
}

.advertisement_stepper .MuiSvgIcon-fontSizeMedium {
    color: #c7c7c7 !important;
    /* border: #CCCCCC !important; */
    height: 19px !important;
    width: 19px !important;
}

.ad_stepper .Mui-disabled svg,
.ad_stepper .Mui-active svg {
    border: unset !important;
    border-radius: 100%;
    height: unset !important;
    width: unset !important;
    margin-top: 0;
}

.rejectIcon img {
    width: 17px;
    position: relative;
    top: -0px;
}
.stepper_div_reject_reason{
    font-size: 10px;
    color: #FF0068;
    cursor: pointer;
    text-align: center;
}
.upload_instruction_adv .MuiDialog-paperFullWidth {
    width: 424px;
    height: 192px;
}
.reject_reason_content{
    font-size: 20px;
}


.excluded-padding{
    padding: 20px 15px !important;
    text-align: center;
}

