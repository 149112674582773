.notification_content_card {
    margin-top: 15px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px !important;
    padding: 10px 20px;
    cursor: pointer;
}

.underline_status {
    border: 1px solid var(--app-theme);
    margin: 8px;
}

.notification_content {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    width: 100%;
}

.notification_content p {
    margin: 0;
}

.clinic_name {
    color: #8c6177;
}

.clinic_time {
    margin-top: 10px !important;
    color: #f7b79f;

}

.email_id {
    font-size: 14px;
    color: var(--app-theme);
    margin: 0px 10px;
}

.clinic_doctor_name {
    color: #f7b79f;
}

.notification_content .content{
    width: 80%;
    text-align: left;
}

.content_time{
    width: 20%;
    text-align: right;
    color: #ff0068;
}
.d_flex{
    display: flex;
}