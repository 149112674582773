/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */


.success_model_body .icons {
  width: fit-content;
  margin: 5px auto 15px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
.active_edit_manage_service{
margin-left: -12px !important;
  padding-bottom: 16px !important;
}