.card_content_one {
  margin-top: 20px;
  box-shadow: 0px 3px 6px #00000029 !important;
}

.drag_drop_img {
  margin-top: 8px;
  background: var(--app-theme);
  margin-left: 3px;
}

.success_post_add_btn {
  background: var(--app-theme) !important;
  border-radius: 23px !important;
  margin: 10px 0px !important;
  color: #fff;
  padding: 8px 30px !important;
  text-transform: capitalize !important;
}

.success_post_add_btnupload {
  background: #D4C3CC !important;
  border-radius: 23px !important;
  margin: 10px 0px !important;
  color: #510F30 !important;
  padding: 8px 30px !important;
  text-transform: capitalize !important;
}


.header_right_content .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 195px !important;
  margin-right: 10px !important;
}

.input_field_services {
  width: 100% !important;
  margin-bottom: 15px !important;
}

.input_field_services input {
  height: 10px !important;
  color: #aaaaaa;
}

/* .content_row_post {
  padding: 5px;
} */

.fileshow {
  align-items: center;
}

.post_content {
  padding-top: 15px !important;
}

.edit_icon_post {
  background-color: var(--app-theme) !important;
  color: #fff !important;
  border-radius: 40px !important;
  margin-right: 8px !important;
  box-shadow: 0px 0px 9px #0000004D !important;
  text-transform: capitalize !important;
  font-size: 14px;
  width: 50px;
  height: 30px;
}

.delete_icon_post {
  background-color: #FF0068 !important;
  color: #fff !important;
  padding: 5px 15px !important;
  font-size: 14px;
  border-radius: 40px !important;
  box-shadow: 0px 0px 9px #0000004D !important;
  text-transform: capitalize !important;
  width: 50px;
  height: 30px;
}

.card_row_img_post {
  height: 50px;
  width: 50px;
  object-fit: cover;
  cursor: pointer;
}

.upload_add_btn {
  text-align: center !important;
}

.input_file {
  position: relative;
}

.post_edit_del_btn {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  gap: 10px;
}

.card_content_one .post_edit_del_btn {
  display: block !important;
  padding-top: 10px;
  gap: 0px;
  text-align: center;
}

.post_active_status {
  text-align: center;
  padding-top: 5px;
}

.post_active {
  font-size: 15px;
}

.post_active_status .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0 !important;
}

.post_active_status .MuiSvgIcon-fontSizeMedium {
  color: var(--app-theme) !important;
  border: #CCCCCC !important;
}

.add_btn_post {
  background-color: #510F30;
  color: white;
  padding: 7px 16px;
  border: 1px solid var(--app-theme) !important;
  border: none;
  border-radius: 30px;
  margin: 8px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.add_btn_post i {
  font-size: 20px;
  margin-right: 10px;
}


.upload_browser_file {
  background-color: var(--app-theme) !important;
  padding: 11px 20px !important;
  font-size: 11px !important;
  position: absolute !important;
  top: 30px !important;
  right: 0 !important;
  cursor: pointer;
}

.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  font-size: 50px;
  opacity: 0;
}

.post_toggle {
  display: flex;
}

.every_day {
  margin-left: 5px !important;
  margin-top: 2px !important;
}

/* alert model design start */
.alert_btn_center {
  display: flex;
  justify-content: center;
  padding-top: 8px;
}

.alert_no_btn {
  font-size: 16px !important;
  font-family: Roboto-Regular !important;
  color: white !important;
  border-radius: 23px !important;
  background-color: var(--app-theme) !important;
  padding: 5px 28px !important;
  margin-right: 10px !important;
  outline: none;
  border: none;
}

.alert_yes_btn {
  font-size: 16px !important;
  font-family: Roboto-Regular !important;
  color: #fff !important;
  border-radius: 23px !important;
  padding: 5px 28px !important;
  margin-left: 10px !important;
  background-color: #ff0068 !important;
  outline: none;
  border: none;
}

.image_content_manage_servive {
  height: 100%;
  width: 100%;
  object-fit: cover;

}

.card_row_img_postfile {
  height: 100px;
  margin: 5px
}

/* alert model design end */

.file_upload {
  height: 100px;
  width: 100%;
  background-color: #D4C3CC;
  margin: 5px;
  text-align: center;
  display: flex;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.file_upload_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.file_upload p {
  font-size: 16px;
  color: #510F30;
  margin: auto;

}

.view_file {
  display: flex;
  justify-content: center;
}

.upltime {
  margin-left: 33px;
}

.upldate {
  margin-left: 40px;
}

.post_title_header label {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.file_alert_icon {
  height: 14px;
  margin-left: 10px;
  margin-top: -2px;
  cursor: pointer;
}

.upload_instruction_full {
  background-color: #510F30;
  box-shadow: 0 0 3.5px 0 #ccc;
  border-bottom: 1px solid #ccc;
  height: 49px;
  display: flex;
  align-items: center;
  padding: 16px !important;
  justify-content: space-between;
}

.upload_ins_close {
  width: 16px;
  cursor: pointer;
}

.uploadins_head {
  font-size: 18px;
  font-weight: 500;
  color: white;
  margin-bottom: 0px;
}

.uploaddIns_div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.upldinsicon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ff0068;
}

.ins_content {
  font-size: 16px;
  margin-left: 10px;
}

.progressBar .MuiLinearProgress-bar {
  color: var(--app-theme) !important;
  background-color: var(--app-theme) !important;
  width: 100% !important;
}

.progressBar .MuiLinearProgress-root {
  color: var(--app-theme) !important;
  background-color: var(--app-theme) !important;
  width: 100% !important;
}

.dialog_header_manage {
  display: flex;
  padding: 10px;
  background-color: var(--app-theme);
}


.view_post_cls {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  width: 100%;
  height: 100%;
}

.success_model_body .failed {
  color: #ff0068;
  font-family: 'TitilliumWeb-Regular' !important;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.post-content-view{
  height: calc(100vh - 22vh) !important;
}


@media (min-width: 1200px) {
  .card_content_one .media_title {
    max-width: 22% !important;
  }

  /* .card_content_one .css-u78q51-MuiGrid-root {
    max-width: 7.333333%;
  } */

  .post_title_header .status_title {
    max-width: 8.5%;
  }

  .content_row_post .post_edit_del_btn {
    display: block;
    padding-top: 10px;
    text-align: center !important;
  }
  .content_row_post .post_active_status {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: start;
    padding-left: 0px !important;
  }
  /* .post_title_header .status_title {
    text-align: center !important;
  } */

  .post_active {
    font-size: 15px !important;
  }
}

@media (min-width: 900px) {
  .card_content_one .media_title {
    max-width: 21%;
  }

  .content_row_post .post_edit_del_btn {
    display: block;
    padding-top: 10px;
    text-align: end;
  }

  .content_row_post .post_active_status {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: start;
    padding-left: 25px;
  }

  /* .post_title_header .status_title {
    text-align: justify !important;
  } */

  .post_active {
    font-size: 13px !important;
  }
}

@media (min-width: 600px) {
  .card_content_one .media_title {
    max-width: 21%;
  }
  .content_row_post .post_active_status {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: start;
    padding-left: 10px;
  }
  .content_row_post .post_edit_del_btn {
    display: block;
    padding-top: 10px;
    text-align: end;
  }
/* 
  .post_title_header .status_title {
    text-align: center !important;
  } */

  .post_active {
    font-size: 14px !important;
  }
}
.css-1hw9j7s.Mui-disabled {
  color: white !important;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled{
  color: white !important;
}
.thumbnil{
  text-align: center !important;
}