/* .card_content {
  margin: 15px 0px 0px 0px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  font-size: 16px;
}

.content_row_manage {
  padding: 15px 10px 15px 50px;
  align-items: center;
}

.profile_head_service {
  margin: 0px;
  font-size: 16px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Durationslight {
  padding-inline-start: calc(2rem - 17px);
}

.profile_head_services {
  margin: 0px;
  font-size: 16px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.edit_icon_service {
  width: 15px;
  height: 15px;
  margin: 0px 8px 0px 15px;
  cursor: pointer;
  position: relative;
}

.edit_icon_service1 {
  width: 15px;
  height: 15px;
  margin: 0px 8px 0px 15px;
  cursor: pointer;
  position: relative;
  left: 0px;
}

.manage_service_data {
  align-items: center;
  display: flex;
}

.image_view_service {
  display: flex;
  justify-content: start;
}

.css-1dt13fn-MuiDialogContent-root {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding: 20px 24px;
  width: 300px;
  height: 295px !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 30px !important;
}

.card_row_img_manage {
  height: 50px;
  width: 50px;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 20px 20px 20px 20px;
}

.dialog_header_manage {
  display: flex;
  margin: -10px -11px;
  padding: 0px 0px 22px 0px;
}

.dialog_header_manage .dialog_title {
  color: var(--app-theme);
  font-size: 18px;
  font-family: TitilliumWeb-Regular;
}

.dialog_header_manage img {
  width: 18px;
  cursor: pointer;
}

.image_content_manage_servive {
  height: 300px;
  width: 300px;
  object-fit: cover;

}

.manage_service_active {
  display: flex;
  justify-content: space-around;
}

.manage_active_status .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0 !important;
}

.manage_active_status .MuiSvgIcon-fontSizeMedium {
  color: var(--app-theme) !important;
  border: #CCCCCC !important;
}

.manage_active {
  font-size: 13px;
  margin-top: 9px;
  margin-left: 10px;
}

.add_btn_manage {
  background-color: #D4C3CC;
  color: var(--app-theme);
  padding: 10px 16px;
  border: 1px solid var(--app-theme);
  border: none;
  border-radius: 30px;
  margin: 8px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.add_btn_manage i {
  font-size: 20px;
  margin-right: 10px;
}

.success_body_size_manage {
  padding-top: 18px;
  color: var(--app-theme);
  font-family: TitilliumWeb-Regular !important;
  font-size: 20px !important;
}

@media (min-width : 1200px){
  .card_content .manage_service_active {
    display: block !important;
    text-align: center;
  }
  .card_content .active-inactive{
    display: block !important;
    text-align: center;
  }
}
@media (min-width:900px){

} */


.card_content {
  margin: 15px 0px 0px 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  font-size: 16px;
}

.content_row_manage {
  /* padding: 15px 10px 15px 50px; */
  padding: 0px 10px 0px 50px;
}

.profile_head_service {
  margin: 0px;
  font-size: 16px;
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.edit_icon_service {
  width: 18px;
  height: 18px;
  margin: 8px 8px 8px 17px;
  cursor: pointer;
}

.manage_service_data {
  align-items: center;
  display: flex;
}

.image_view_service {
  display: flex;
  justify-content: start;
}

.card_row_img_manage {
  height: 50px;
  width: 50px;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 20px 20px 20px 20px;
}

.dialog_header_manage {
  display: flex;
  /* margin: 0px 20px;
  padding: 10px; */
  margin: -10px -11px;
  padding: 0px 0px 22px 0px;
  /* border-bottom: 1px solid #000; */
}

.dialog_header_manage .dialog_title {
  color: var(--app-theme);
  font-size: 17px;
  font-family: TitilliumWeb-Regular;
}

.dialog_header_manage img {
  width: 18px;
  cursor: pointer;
}

.image_content_manage_servive {
  height: 300px;
  width: 300px;
  object-fit: cover;
}

.manage_service_active {
  display: flex;
  justify-content: center;
}

/* .manage_active_status {
  display: flex;
  justify-content: center;
} */

.manage_active_status .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0 !important;
}

.manage_active_status .MuiSvgIcon-fontSizeMedium {
  color: var(--app-theme) !important;
  border: #cccccc !important;
}

.manage_active {
  font-size: 13px;
  margin-top: 9px;
  margin-left: 10px;
}

.MuiDialog-paper {
  border-radius: 30px !important;
}

.css-yn499x {
  height: -webkit-fill-available !important;
}

.css-14m1cto-MuiDialogContent-root {
  height: -webkit-fill-available !important;
}
.image_body_sizes {
  height: 80px;
  width: 80px;
}
.Durationslight {
  padding-inline-start: calc(2rem - 17px);
}
.Durationslights {
  padding-inline-start: calc(2rem - 14px);
}
.page_head_doctor_container {
  display: flex;
  align-items: center;
  margin: 0 0.3rem;
}

.content_row_manage_new {
  padding: 21px 10px 21px 50px;
  border: 1px solid var(--app-theme);
  background-color: var(--app-theme);
  color: white;
  border-radius: 11px;
}
.content_row_manage_data {
  padding: 12px 10px 12px 50px;
  /* border: 1px solid var(--app-theme); */
  background-color: #31897d33 !important;
  color: black !important;
  /* border-radius: 11px; */
}
.card_content_new {
  margin: 15px 0px 0px 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 3px 6px #00000029; */
  border-radius: 2px;
  font-size: 16px;
}
/* .content_view_manage_service{
  margin-top: 90px;
  padding: 0px 35px;
  height: calc(100vh - 165px);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  overflow-y: auto;
  padding: 15px 50px;
} */

.content_view_manage_service {
  height: 76vh;
  margin-top: 24vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0rem 3rem 1rem 3rem;
}

.card_details_service {
  margin-bottom: 12px;
  border: 1px solid rgba(47, 136, 124, 0.3);
  margin-top: 10px;
  box-shadow: 0px 0px 6px -3px;
  border-radius: 10px;
  height: 50vh;
  display: flow;
  overflow-x: auto;
}
.pagination_list_show {
  /* margin-top: 20px; */
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 13px;
}
.page_list_pagination {
  display: flex;
  gap: 16px;
}
.row_page_details {
  height: 33px;
  width: 33px;
  color: black;
  background-color: #e0e0e0;
}
.active > .page-link,
.page-link.active {
  color: white;
  background-color: var(--app-theme);
  border-color: var(--app-theme);
}


