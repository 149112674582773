* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("./assets/font/ROBOTO/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb-Regular";
  src: url("./assets/font/Titillium_Web/TitilliumWeb-Regular.ttf") format("truetype");
}


:root {
  --app-theme: #2F887C;
  --app-sub-header: #378c81;
}

/* common css start */
.flex_grow {
  flex-grow: 1;
}

.segment_center {
  width: fit-content;
  margin: auto;
  /* padding-bottom: 10px; */
}

.content_view {
  margin-top: 90px;
  /* margin-top: 120px; */
  padding: 0px 35px;
  height: calc(100vh - 165px);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  overflow-y: auto;
}

.service_type_img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0px 15px 0px 0px;
}

.warning_helper_txt {
  color: red;
  text-align: center;
  background-color: #ff000030;
  padding: 10px;
  border-radius: 5px;
  text-transform: capitalize;
}

/* common css end */
.btn-primary {
  --bs-btn-color: #000000 !important;
  --bs-btn-bg: none !important;
  --bs-btn-border-color: #000000 !important;
  --bs-btn-hover-color: #000000 !important;
  --bs-btn-hover-bg: none !important;
  --bs-btn-hover-border-color: #000000 !important;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: none !important;
  --bs-btn-active-border-color: none !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: none !important;
  --bs-btn-disabled-border-color: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaaaaa;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #acacac;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  color: #000000 !important;
  background-color: none(--bs-btn-active-bg) !important;
  border-color: #000000 !important;
}

.btn:hover {
  color: #000000 !important;
}

.dropdown_button:hover {
  background-color: none !important;
}

.dropdown_button:active {
  background-color: none !important;
}

.border_bottom {
  border: 0px !important;
  border-bottom: 1px solid gray !important;
}

.page_head {
  margin: 4px 20px;
  font-size: 18px;
}

.btnWithNOSHade {
  all: unset
}

.botton_Cart_Create {
  color: #ffffff !important;
  border-radius: 25px !important;
  background-color: var(--app-theme) !important;
  /* padding: 10px 20px; */
  background: var(--app-theme) 0% 0% no-repeat padding-box;
  border: none;
  /* height: 40px;
  width: 85px; */
  padding: 8px 30px;
  font-size: 16px;
  font-weight: 500;
}

.botton_Cart_Create_Ok {
  color: #ffffff !important;
  border-radius: 25px !important;
  background-color: #ff0068 !important;
  /* padding: 10px 20px; */
  background: #ff0068 0% 0% no-repeat padding-box;
  border: none;
  height: 40px;
  width: 85px;
  font-size: 16px;
  font-weight: 500;
}


.btn-close {
  --bs-btn-close-bg: url('../src/assets/icon/Icon\ ionic-ios-close.png') !important;
  --bs-btn-close-opacity: 0.75 !important;
  --bs-btn-close-hover-opacity: 2 !important;
}

.content_view_appointment {
  height: 70vh;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 20vh;
  padding: 0px 1rem 7px;
}

.detailsprofile {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.profile_Details {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin: 20px;
  box-shadow: #00000029 0px 3px 6px;
}

.Model_success_text {
  font-size: 22px;
  margin: 20px 0;
  color: var(--app-theme);
}

.Model_content_text {
  color: #595959;
}

.success_button {
  margin: 0 !important;
}

.Header_Select_Label {
  width: 200px;
  margin-top: 25px;
}

.react-calendar {
  height: 65vh !important;
  width: -webkit-fill-available !important;
}

.header_back_button {
  font-size: 28px;
  color: #000000;
  margin-left: 30px;
}

.success_body_size {
  font-family: TitilliumWeb-Regular !important;
  font-size: 18px !important;
  color: var(--app-theme) !important;
}

.success_msg {
  font-size: 16px !important;
  font-family: Roboto-Regular !important;
  color: #000 !important;
}

.input_span {
  font-size: 16px !important;
  font-family: Roboto-Regular !important;
  color: #000000 !important;
}

.success_ok_btn {
  font-size: 16px !important;
  font-family: Roboto-Regular !important;
  color: var(--app-theme) !important;
  padding: 5px 30px !important;
  outline: none !important;
  border: none !important;
  border-radius: 23px !important;

}

.success_card {
  padding-top: 10px !important;
}

.error {
  text-align: left;
  margin-top: 0.4rem;
  color: #ff0068;
  font-size: 12px;
  font-family: "Roboto-Regular" !important;
  ;
}


.specialtyimgRow {
  margin: 0;
  width: 100% !important;
}

.selectPval {
  margin-bottom: 0 !important;
}

/* =============== sub header style start =============== */

.sub_header {
  /* padding: 10px 35px; */
  padding: 0 1rem;
  background-color: #ffffff;
  width: 100%;
  height: 10vh;
  position: fixed;
  top: 0px;
  left: 0px;
  border-bottom: 1px solid #dddddd;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 999;
  overflow: hidden;
}

.sub_header .sub_header_body {
  display: flex;
  height: 10vh;
}

.PosZindex {
  position: relative;
  z-index: 3;
}

.sub_header .sub_header_body .back_navigation {
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
  margin: 15px 0px;
  display: flex;
  justify-content: center;
  align-items: end;
}

.sub_header .sub_header_body .back_navigation i {
  font-size: 30px;
  /* font-weight: bold; */
  margin-top: -2px;
  color: #000000;
}

.sub_header .sub_header_body .back_navigation span {
  font-size: 18px;
  margin-left: 13px;
  font-family: TitilliumWeb-Regular !important;
  /* color: var(--app-theme); */
  color: #000000;
  margin-top: 2px;
}

.sub_header .sub_header_body .header_right_content {
  display: flex;
  align-items: flex-end;
}

.css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
  color: #000000 !important;
}

/* .MuiInputBase-inputSizeSmall {
  color: #aaaaaa !important;
} */

.add_btn {
  background-color: #D4C3CC;
  color: var(--app-theme);
  padding: 10px 16px;
  /* border: 1px solid var(--app-theme); */
  border: none;
  border-radius: 30px;
  margin: 8px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.add_btn i {
  font-size: 20px;
  margin-right: 10px;
}

/* =============== sub header style end =============== */

/* =============== textbox file upload start =============== */
.fileUpload {
  background: transparent;
  border-radius: 5px;
  color: #0e0e0e;
  font-size: 1em;
  font-weight: bold;
  overflow: hidden;
  padding: 8px;
  position: relative;
  text-align: right;
  width: 100%;
  cursor: pointer;
  border: 1px solid #c4c4c4;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 39px;
  cursor: pointer;
}

.fileUpload span img {
  width: 14px;
  margin: 0px 9px;
}

.title_dashboard {
  width: 100%;
  padding: 5px 15px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px 5px 0px 0px;
  height: 60px;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fileUpload p {
  position: absolute;
  top: 9px;
  left: 10px;
  font-size: 16px;
  font-weight: 200;
  margin: 0px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: #C8C8C8;
}

.css-vp68fu-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  padding: 0px !important;
  color: #C8C8C8;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: rgb(212, 195, 204) !important;
}

/* =============== text box file upload end =============== */

/* =============== Mui css text box inside text css start =============== */
.css-1pnmrwp-MuiTypography-root {
  font-size: 10px !important;
}

/* =============== Mui css text box inside text css start =============== */

.loader_body {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgb(255 255 255 / 85%);
  z-index: 99999999;
}

.progressBar {
  width: '100%';
}

.css-1idz92c-MuiCircularProgress-svg {
  color: black;
}

/* CustomLinearProgress.css */
.custom-linear-progress {
  /* Adjust the animation duration as needed */
  animation: custom-progress 4s linear infinite;
}

@keyframes custom-progress {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}


.notification_container {
  display: flex;
  width: 500px !important;
}

.notification_img_container {
  margin: 3px;
  width: 20%;
}

.notification_img {
  display: flex;
  margin: 3px;
  width: 100%;
  height: 100%;
}

.notification_text_container {
  margin: 3px;
  width: 80%;
}

.notification_text_head {
  margin: 5px 10px;
  font-size: 16px !important;
}

.notification_text_body {
  font-size: 14px !important;
  margin: 5px 10px;
}

.go2072408551 {
  max-width: fit-content !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #31897d33;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--app-theme);
  border-radius: 5px;
  width: 10px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #510F30;
} */

.success_model_body .title {
  color: var(--app-theme);
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-family: TitilliumWeb-Regular;
}

.success_model_body .failedtxt {
  color: #ff0068 !important;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-family: TitilliumWeb-Regular;
}

.image_body_sizes {
  height: 80px;
  width: 80px;
}


.confirmation_model_body .image_division {
  background: #2f887c;
  border-radius: 50%;
  width: fit-content;
  margin: 2rem auto 1rem;
}
.confirmation_model_body .image_icon {
  height: 70px;
  width: 70px;
  margin: 1.2rem;
}
.confirmation_model_body .confirm_header {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
}
.confirmation_model_body .confirm_content {
  color: #595959;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
}

.confirm_btn {
  display: flex;
  justify-content: space-around;
  margin: 2rem;
}
.confirm_no_btn {
  font-size: 18px !important;
  font-weight: 600;
  color: var(--app-theme) !important;
  padding: 0.8rem 2rem !important;
  margin-right: 10px !important;
  background-color: #2f887c75;
  border: none;
  border-radius: 25px;
}
.confirm_yes_btn {
  font-size: 18px !important;
  font-weight: 600;
  color: #dc3545 !important;
  padding: 0.8rem 2rem !important;
  margin-right: 10px !important;
  background-color: #f49c7a;
  border: none;
  border-radius: 25px;
}
.confirm_model {
  width: 100%;
}


.Navication-header {
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  background-color: #31897d33;
  margin: 1rem 0rem 1.6rem 0rem;
  position: absolute;
  width: 100%;
  top: 11vh;
  /* box-shadow: 0px 3px 6px #2F887C75;
  opacity: 0.6; */
}
.Navication-header label {
  cursor: pointer;
  font-size: 25px;
  color: var(--app-sub-header);
  font-family: Poppins !important;
}
.Navication-header i {
  font-size: 25px;
  padding: 0rem 0.5rem;
  color: var(--app-sub-header);
}
.Navication-header img {
  padding: 0rem 0.5rem 0.4rem 0.5rem;
  cursor: pointer;
}
.popup_main {
  position: fixed;
  z-index: 999999;
  height: 100%;
  width: 100%;
  top: -2vh;
  left: 0;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.success_Popup_body {
  position: relative;
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 10px;
  width: fit-content;
  margin: auto;
  border-radius: 0px 0px 54px 54px;
}
.success_model_content {
  padding: 4vh 4vh 1vh 4vh;
  font-size: 25px;
}
.success_content_text_head {
  margin: 5px 10px;
  font-size: 25px !important;
  color: var(--app-sub-header);
}
.progress_prt {
  padding: 0vh 5vh;
}
.progress_bar {
  height: 4px;
  width: 100%;
  background-color: #ffffff; /* Light green for background */
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}

.progress_bar::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--app-sub-header); /* Darker green for progress */
  animation: progress 3s linear forwards; /* Adjust timing to match duration */
  border-radius: 10px;
}

@keyframes progress {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}