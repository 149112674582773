.segment_row {
  margin-top: 35px;
}

.segment {
  margin: 10px;
  width: 54%;
}

.month,
.quarter,
.year {
  margin: 10px 0;
}

.date_row {
  margin-top: 16px;
}

.left_button_revenue {
  color: #264000;
}

.right_button_revenue {
  color: #264000;
}

.revenue_doctor_income {
  width: 90%;
  margin: auto !important;
}

.revenue_chart_header {
  font-weight: 600;
}

.chart_card {
  width: 100%;
  box-shadow: #5A5A5A2E 0px 5px 15px !important;
  padding: 20px;
}

.chart_barcharts {
  margin: 20px;
}

.fine {
  margin-bottom: 61px;
}

.amount_details_revenue {
  display: flex;
  gap: 10px;
  justify-content: space-around;
  margin-bottom: 17px;
}

.check_box_revenue {
  display: flex;
  gap: 15px;
  align-items: center;
}

.tom_revenue {
  height: 17px;
  width: 17px;
  border: 2px solid #264000;
  background-color: #264000;
}

.doctor_revenue {
  height: 17px;
  width: 17px;
  border: 2px solid #83AF40;
  background-color: #83AF40;
}

.doughnut_chart_row {
  margin-top: 2%;
}

.doughnut_revenue {
  display: flex;
  justify-content: center;
}

.dived_total_count {
  display: flex;
  gap: 30px;
  justify-content: center;
}

.total_slots_revenue {
  font-weight: 600;
  text-align: center;
}

.total_slots_revenue span {
  font-weight: 500;
  text-align: center;
  color: grey;
}

.revenue_booked_details {
  display: flex;
}

.doughnut_available {
  border: 3px solid #264000;
  margin: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;
}

.doughnut_booked {
  border: 3px solid #83AF40;
  margin: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;

}

.revenue_available_details {
  display: flex;
}

#dongChart {
  width: 300px !important;
  height: 300px !important;
}

.chartjs-tooltip {
  display: none !important;
}

.revenue_under_line {
  border-top: var(--bs-border-width) dashed;
  --bs-border-width: 2px;
}

.content_view {
  padding: 0 !important;
}

.chart_id {
  width: 89%;
  height: 309px;
}

.bookingChartDiv {
  width: 50%;
  margin: auto;
  box-shadow: #5A5A5A2E 0px 5px 15px !important;
  padding: 20px;
  margin-top: 20px;
  height: 360px;
  margin-bottom: 30px;
}

.revenue_lbl {
  width: 50%;
  margin: auto;
  margin-top: 20px;
}

.revenue_checkLbl div {
  background: #83AF40;
  color: #83AF40;
  margin-right: 3px;
  width: 12px;
  height: 8px;
}

.revenue_checkLbl {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.revenue_checkPayable div {
  background: #AF40AF;
  color: #AF40AF;
  margin-right: 3px;
  width: 12px;
  height: 8px;
}

.revenue_checkPayable {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 40px;
}
.total_revenue div {
  background: #83AF40;
  color: #83AF40;
  margin-right: 3px;
  width: 12px;
  height: 8px;
}

.total_revenue {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.revenue_checkreceivable div {
  background: #4047AF;
  color: #4047AF;
  margin-right: 3px;
  width: 12px;
  height: 8px;
}

.revenue_checkreceivable {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 24px;
}
.revenue_Receivable div {
  background:#00BCD4;
  color:#00BCD4;
  margin-right: 3px;
  width: 12px;
  height: 8px;
}

.revenue_Receivable {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 24px;
}
.revenue_checkpayablesixty div {
  background:#26A69A;
  color:#26A69A;
  margin-right: 3px;
  width: 12px;
  height: 8px;
}

.revenue_checkpayablesixty {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.revenue_checkOverDue div {
  background: #F45959;
  color: #F45959;
  margin-right: 3px;
  width: 12px;
  height: 8px;
}

.revenue_checkOverDue {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
}