.collateals_tabs {
    width: 100%;
    padding-top: 25px;
}

.main_card_images {
    margin-top: 17vh;
}

.first_row_card_images {
    /* margin-bottom: 6vh; */
    margin-bottom: 2vh;
}

.collateals_tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: var(--app-theme);
}

.collateals_tabs .css-1aquho2-MuiTabs-indicator {
    background-color: var(--app-theme);
}

.collaterals_row {
    padding-top: 20px;
}

.collaterals_card_one {
    padding: 15px 10px;
}

.collaterals_label {
    text-transform: capitalize !important;
}

.collaterals_card_two {
    padding-left: 70px;
}

.collaterals_card_video {
    width: 200px;
    height: 130px;
}

.collaterals_card_img {
    width: 170px;
    height: 130px;
}

.collaterals_card_title {
    font-weight: bold;
}

.collaterals_title {
    font-weight: bold;
    margin-right: 5px;
    margin-bottom: 5px !important;
    margin: 0;
}

.collaterals_data {
    margin-left: 8px !important;
    margin-bottom: 5px !important;
    font-weight: bold;
    color: #666666;
    margin: 0;
}

.collaterals_card {
    padding: 15px 30px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
}

.collaterals_video_ul {
    padding: 0px;
}

.collaterals_video_ul li {
    display: inline;
    text-decoration: none;
    padding: 8px;
    cursor: pointer;
}

.collaterals_video_ul li.active {
    color: var(--app-theme);
    border-bottom: 5px solid var(--app-theme);
}

.collaterals_main_div {
    /* margin-top: 80px !important; */
    padding-bottom: 80px;
    /* margin: 80px 0px 0px 50px !important; */
    margin: 115px 0px 0px 50px !important;
}

.collateral_play_img {
    /* padding-top: 160px;
    cursor: pointer; */
    /* padding-left: 9px; */
    /* padding-left: 5px; */
    position: relative;
    top: 80%;
    right: 0%;
    transform: translate(0%, 0%);
}

.collateral_card_img {
    position: relative;
    z-index: 9;
    height: 190px;
    width: 260px;
    z-index: 1 !important;
    background: #fff !important;
}

.card_image_content_collateral {
    margin: 0.5em 1em 0.5em 1em;
}

.video-icon4 {
    position: absolute;
    bottom: 21px;
    z-index: -1;
}

.support_profile_img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 5px 10px 5px 5px;
    position: relative;
    z-index: 1;
}

.support-container {
    position: relative;
    z-index: 1;
}

.video_support {
    top: 35px;
    position: absolute;
    right: -30px;
    z-index: -1;
}

.video_profile {
    top: 45px;
    position: absolute;
    right: -14px;
    z-index: 0;
}