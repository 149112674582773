/* .main_card_image {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
} */

.home_card_title {
    margin: 5px 0 0 0;
    color: var(--app-theme);
}

.card_image {
    /* height: 200px;
    width: 270px; */
    border-radius: 10px;
    /* box-shadow: inset 0 0 2px #333; */
    /* border: solid 2px var(--app-theme); */
    border: solid 1px var(--app-theme);
    box-shadow: 0 3px 6px #00000029;
    height: 26vh;
    padding: 1rem;
    width: 16vw;
}

.card_image_content {
    /* margin: 1em; */
    margin: 5px;
    /* transition: transform 0.5s; */
    cursor: pointer;
    /* box-shadow: inset 0 0 5px #333; */
}



.first_row_card_image,
.first_row_div_image {
    margin: 0;
}

.image_div {
    /* background-color: aqua; */
    /* height: 205px; */
    /* width: 277px; */
    overflow: hidden;
}

.mr_t_2rem {
    /* margin-top: 2rem; */
    margin-top: 1rem;

}

.dash_table {
    text-align: center !important;
}



.css-twia2z-MuiPaper-root-MuiDialog-paper {
    border-radius: 20px !important;
}

.MuiDialog-paper {
    border-radius: 20px !important;
}











.segment_center {
    display: flex;
    justify-content: right;
    width: auto;
}

.popButton {
    margin-top: 10%;
}

.dash_head {
    color: white !important;
    font-weight: 500 !important;
}

.dashImage {
    height: 244px;
    width: 400px;
}

.dateOf {
    text-align: left;
    font-size: 17px;
    color: rgb(47, 136, 124);
    margin-left: 20px;
    font-weight: bold;
    margin-bottom: 5px;
    font-family: "Roboto-Regular";
    text-transform: uppercase;
    margin-left: 40px;
}

.positionOf {
    position: absolute;
    top: -10px;
}


.walk_model_header_text {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    font-family: "Roboto-Regular" !important;
    margin: 0px;
}

.walk_model_header_text_valid {
    color: Red;
    font-size: 20px;
    font-weight: 600;
    font-family: "Roboto-Regular" !important;
    ;
}

.walk_appointment_booking {
    padding-top: 15px;
}

.walk_appointment_booking_button {
    width: fit-content;
    margin: auto;
    padding-top: 35px;

}

.walk_appointment_book_button {
    color: #ffffff !important;
    border-radius: 5px !important;
    background-color: #2F887C !important;
    padding: 8px 30px;
    background: #2F887C 0% 0% no-repeat padding-box;
    border: 1px solid #2F887C;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
}

.walk_appointment_cancel_button {
    background-color: #ffffff !important;
    border-radius: 5px !important;
    color: #2F887C !important;
    padding: 8px 30px;
    background: #2F887C 0% 0% no-repeat padding-box;
    border: 1px solid #2F887C;
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
}

.walk_model_left_part {
    padding: 0px 30px 0px 12px;
}

.walk_model_right_part {
    padding: 0px 12px 0px 19px;
}


.barCharts {
    border: 2px solid rgb(243, 240, 240);
    margin-left: 26px;
    margin-right: 50px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
}

/* .main_card_image {
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-height: 368px;
    overflow-y: scroll;
} */

.main_card_image {
    padding: 72px 26px 0px 6px;
}

.dashImagePosition {
    margin-top: -6% !important;
}

/* @media only screen and (min-width: 500px) and (max-width: 580px) {
    .dashImagePosition {
        margin-top: -30px !important;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .dashImagePosition {
        margin-top: -82px !important;
    }
} */

.headerTextOf {
    color: rgb(255, 255, 255);
    font-weight: 600;
    margin-left: 30px;
    font-size: 35px;
    margin-top: 5%;
}

.headerTextOf2 {
    color: rgb(255, 255, 255);
    font-size: 16px;
    margin-left: 30px;
}

.addAppoint {
    color: #FFFFFF;
    font-size: 16px;
}

.addPlus {
    border: 2px solid white;
    width: 48%;
    height: 50%;
    border-radius: 6px;
}

.addPlus2 {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-top: -6px;
}

.appointGrid {
    background-color: rgb(47, 136, 124);
    border-radius: 8px;
    margin-left: 20px;
    width: 14vw;
    display: flex;
    align-items: center;
    height: 40px;
    transition: all 0.5s ease;
}

.appointGrid:hover {
    border: 0.5px solid #2f887ca1;
    /* background-color: white; */
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.505);

    /* color: rgb(47, 136, 124); */

}

/* .appointGrid:hover .addAppoint{
    color: rgb(47, 136, 124);
}
.appointGrid:hover .addPlus2{
    color: rgb(47, 136, 124);
} */

/* .appointGrid:hover .addPlus{
    border: 2px solid rgb(47, 136, 124) ;
} */

.no_data_found {
    font-size: 20px;
    font-family: "Roboto-Regular" !important;
    font-weight: 600;
    padding-top: 35px;
    margin: 0px;
}

.dashboard_table_card_line {
    width: 65px;
    border-bottom: 2px solid #2A7A6F;
    margin: 0px;
    color: var(--app-theme);
    margin-bottom: 15px;
}

.dashboard_table_card_line2 {
    width: 40px;
    border-bottom: 2px solid #2A7A6F;
    margin: 0px;
    color: var(--app-theme);
    margin-bottom: 15px;
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    .headerTextOf {
        color: rgb(255, 255, 255);
        font-weight: 600;
        margin-left: 30px;
        font-size: 16px;
        margin-top: 5%;
    }

    .headerTextOf2 {
        color: rgb(255, 255, 255);
        font-size: 13px;
        margin-left: 30px;
    }

    .serviceImage {
        margin-top: 6%;
    }
}


@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .addAppoint {
        color: rgb(255, 255, 255);
        font-size: 10px;
    }

    .addPlus {
        border: 2px solid white;
        width: 43%;
        height: 38%;
        border-radius: 4px;
    }

    .addPlus2 {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        margin-top: -8px;
    }

    .appointGrid {
        background-color: rgb(47, 136, 124);
        border-radius: 8px;
        margin-left: 20px;
        width: 18%;
        display: flex;
        align-items: center;
        height: 34px;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
    .headerTextOf {
        color: rgb(255, 255, 255);
        font-weight: 600;
        margin-left: 30px;
        font-size: 35px;
        margin-top: 5%;
    }

    .headerTextOf2 {
        color: rgb(255, 255, 255);
        font-size: 16px;
        margin-left: 30px;
    }

    .appointGrid {
        background-color: rgb(47, 136, 124);
        border-radius: 8px;
        margin-left: 20px;
        width: 18%;
        display: flex;
        align-items: center;
        height: 50px; 
    }

    .addAppoint {
        color: #FFFFFF;
        font-size: 16px;

    }

    .addPlus {
        border: 2px solid white;
        width: 48%;
        height: 50%;
        border-radius: 6px;
    }

    .addPlus2 {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 28px;
        margin-top: -10px;
    }

}

.barChartsHead {
    margin-bottom: 63px;
    padding-right: 30px;
}

.barMargin {
    margin-left: 3%;
    margin-top: 13px;
}

.barIncome {
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 0px;
}


.services1 {
    border-bottom: 3px solid #2F887C;
    border-radius: 10px;
    text-align: left;
    width: 85%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
}

.services2 {
    border-bottom: 3px solid rgb(255, 78, 121);
    border-radius: 10px;
    text-align: left;
    width: 85%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
}

.services3 {
    border-bottom: 3px solid rgb(255, 199, 54);
    border-radius: 10px;
    text-align: left;
    width: 85%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px
}

.services4 {
    border-bottom: 3px solid rgb(0, 143, 251);
    border-radius: 10px;
    text-align: left;
    width: 85%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px
}

.dashboard_dialog_header {
    display: flex;
    padding-right: 20px;
    padding-top: 7px;
}

.dashboard_select_finder {
    display: flex;
    justify-content: space-around;
    padding-top: 25px;
}

.dashboard_select_icon {
    display: flex;
}

.dashboard_check_box_booked {
    height: 15px;
    width: 15px;
    background-color: #fbeae5;
    border: 1px solid #ff0068;
    margin: 5px;
}

.booked {
    color: #ff0068;
}


.dashboard_check_box_available {
    height: 15px;
    width: 15px;
    background-color: #2a7a6f40;
    border: 1px solid var(--app-theme);
    margin: 5px;
}

.dashboard_check_box_selected {
    height: 15px;
    width: 15px;
    background-color: var(--app-theme);
    border: 1px solid var(--app-theme);
    margin: 5px;
}

.selected,
.available {
    color: rgb(47, 136, 124);
}


.dashboard_total_slots_top {
    margin-top: 0px;
}

.dashboard_time_show {
    padding: 6px 10px 6px 15px;
    margin: 0;
    background-color: #510f3040;
    color: var(--app-theme);
    font-size: 13px;
    height: 32px;
    width: 84px;
    border-radius: 3px;
    border-width: 2px !important;
    text-transform: lowercase !important;
}

.dashboard_time_sloat {
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.classSym {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}


.appointGap {
    margin-top: 2%;
    margin-bottom: -23px;
}

.appointGap2 {
    margin-top: 1%;
}

.appointBox {
    margin-bottom: 40px;
    font-weight: 600;
    margin-left: 10px;
    padding: 7px;
    font-family: "Roboto-Regular" !important;
    font-size: 18px;
}


.services1:hover,
.services2:hover,
.services3:hover,
.services4:hover {
    transform: scale(1.05);
    box-shadow: rgba(99, 99, 99, 0.3) 0px 4px 12px 0px;
    background-color: #fff;
    transition: all 0.3s ease;
}


.holder_input_name {
    border: none;
    margin: 10px 0;
    outline: none;
    width: -webkit-fill-available;
    font-size: 16px;
    border: 1px solid var(--app-theme);
    padding: 5px 0px 5px 15px;
    border-radius: 5px;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    box-shadow: 0px 2px 7px 0px #31897D52;

}

.dashboard_next_patient_content_symptoms {
    color: rgb(47, 136, 124);
    height: 44px;
    width: 6vw;
    /* width: 400px; */
    border-radius: 7px;
    border: 1px solid rgb(47, 136, 124);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    background-color: #2a7a6f40;
    padding: 7px 9px;
    font-family: "Roboto-Regular";
    font-size: 18px;
    font-weight: 500;
}

.nextPatient {
    margin-top: 20px;
    font-weight: 600;
    margin-left: 20px;
    font-size: 18px;
}

.nextName {
    color: rgb(47, 136, 124);
    font-weight: 600;
}

.symptomsOf {
    display: flex;
    justify-content: center;
}

.Dashboard_Add_Appointment_Image {
    width: 20px;
    height: 20px;
}

.appointment_page_add_icon {
    height: 21px !important;
    width: 40px !important;
}

.dashboard_content_button {
    padding: 7px 25px 7px 10px;
    border: 1px solid var(--app-theme);
    background: var(--app-theme);
    color: #fff;
    border-radius: 5px;
    font-family: "Roboto-Regular";
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.dashboard_content_button:hover {
    background: #3da394;
}

.dashboard_table_card_row {
    margin: 0px;
    padding-top: 20px;
}

.dashboard_table_card_header {
    background-color: var(--app-theme);
    color: #fff;
}

.dashboard_table_card_header_text {
    padding-top: 8px;
}

.dashboard_upcoming_appointment_data {
    overflow-y: auto;
    height: 225px;
}

.dashboard_card_content_new {
    margin: 3px 0px 0px 0px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 2px;
    font-size: 16px;
    color: #000;
}

.dashboard_content_row_data {
    background-color: #2a7a6f40 !important;
    color: #000 !important;
}

.dashboard_content_card_text_revenue {
    font-weight: 600;
    font-family: "Roboto-Regular" !important;
    font-size: 25px;
    color: #0d83eb !important;
    padding-left: 10px;
    margin-bottom: 7px;
}
.react-datepicker-popper {
    z-index: 9999 !important;
  }
  .symptom_input{
    width: 95%;
    border: none !important;
    outline: none !important;
  }
  .symptom_parent{
    border: none;
    margin: 10px 0;
    outline: none;
    width: -webkit-fill-available;
    font-size: 16px;
    border: 1px solid var(--app-theme);
    padding: 6px;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2);
    display: flex;
  }
  .symptom_add_icon{
    height: 20px;
      width: 20px;
      margin-top: 2px;
  }
  .symptoms_chip_main{
    background: #378c81;
    color: white;
    width: fit-content;
    border-radius: 15px;
    padding: 4px 10px;
    margin: 5px;
    display: inline-table;;
  }
  .symptoms_remove_icon{
    height: 12px;
      width: 12px;
  }
  .dashboard_next_patient_details_row3 {
    padding-top: 25px;
    text-align: center;
    overflow: hidden;
  }
  .dashboard_next_patient_content_symptoms {
    background-color: #2a7a6f40;
    color: var(--app-theme);
    margin: 0px;
    border-radius: 6px;
    font-size: 20px;
    font-family: "Roboto-Regular";
    width: auto;
    padding: 6px;
    padding: 7px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    display: inline-table;
    margin: 10px;
  }