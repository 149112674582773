.referral_data {
    align-items: center;
    display: flex;
}

.referral_data_icon {
    display: flex;
    justify-content: end;
}

.referraldoctorname {
    font-size: 17px;
    font-weight: 400;
    color: #510F30 !important;
    margin-bottom: 0px !important;
}

.content_row_referral {
    /* margin-top: 85px; */
    margin-top: 90px;
    background: #fff;
    z-index: 1;
    position: absolute;
    padding: 5px 26px 7px 68px;
    width: 98% !important;
}

.referraldoctorcard1 {
    width: 100%;
    height: 100px;
    padding: 18px;
    margin-top: 15px;
}

.referraldoctorcard2 {
    width: 100%;
    height: 100px;
    padding: 0px 15px;
    margin-top: 15px;
}

.referraldoctorgender {
    text-transform: capitalize;
}

.referraldoctorpatientname {
    font-size: 14px;
    color: #6c757d;
    font-weight: 400;
    margin-bottom: 0px !important;
}

.referraldoctorpatientname_patient {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    margin-bottom: 0px !important;
}

.referraldateandpatientname {
    display: flex;
    justify-content: space-between;
}

.referraldoctorpatientname_address {
    color: #6c757d;
    font-size: 14px;
    padding: 4px 0px;
    border-top: 1px solid #d5d5d5;
}

.referral_data_icon .css-598tal-MuiSwitch-root .MuiSwitch-track {
    background-color: #510F30 !important;
}

.referral_data_icon .css-xuydw6 .MuiSwitch-track {
    background-color: #510F30 !important;
}

.toggle_referral_before {
    right: 39px;
    top: 7px;
    position: absolute;
    z-index: 1;
    transform: rotate(172deg);
}


.toggle_referral_after {
    right: 55px;
    top: 7px;
    position: absolute;
    z-index: 1;
    transform: rotate(358deg);
}