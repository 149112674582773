.VendorDetailsDiv table {
  border-collapse: separate;
  border-spacing: 0 5px;
  background-color: transparent;
  padding: 15px;
}

.VendorDetailsDiv tr {
  /*box-shadow: 0 0 0.5px #000;*/
  background: white;
}

.VendorDetailsDiv thead tr {
  background: transparent !important;
}

.VendorDetailsDiv tr,
.VendorDetailsDiv th,
.VendorDetailsDiv td {
  border-bottom: 0px solid rgba(224, 224, 224, 1);
}

.VendorDetailsDiv .addemployeebtn {
  text-align: left;
  padding-bottom: 20px;
}

.VendorDetailsDiv .addemployeetxt {
  background: white;
  width: 177px;
  padding: 8px;
  justify-content: space-between;
  box-shadow: 1px 1px 1px 1px #ccc;
  display: inline-flex;
  align-items: center;
}

.VendorDetailsDiv .employeeTitle {
  padding-left: 15px;

  font-size: 25px;
  color: #333;
}

.VendorDetailsDiv .Mui-selected,
.VendorDetailsDiv tbody tr:hover {
  background-color: white !important;
  box-shadow: 0px 0 10.5px #ddd;
}

.VendorDetailsDiv tbody th,
.VendorDetailsDiv tbody td {
  /* color: #b5abab; */
  color: #7b7171;
  font-size: 15px;
}

.Mui-selected th,
.Mui-selected td {
  color: #000 !important;
}

.VendorDetailsDiv .addemployeetxticon {
  padding-left: 10px;
  color: green;
  font-size: 22px;
  /* padding-top: 14px; */
  color: green;
  /* margin-top: 20px; */
  /* padding-top: 12px; */
  display: inline-flex;
}

.VendorDetailsDiv th,
.VendorDetailsDiv td {
  /* padding-left: 15px; */
  text-align: center;
}

/*sear css */
.VendorDetailsDiv .searchicon {
  margin-right: 4% !important;
  padding-top: 2%;
}

.VendorDetailsDiv .searchicontxt {
  color: #83af40;
  font-size: 24px;
}

.VendorDetailsDiv .paper {
  width: "100%";
  margin-bottom: 10px;
  background-color: "transparent";
}

.VendorDetailsDiv table {
  min-width: 750;
}

.VendorDetailsDiv .tableWrapper {
  overflow-x: auto;
  margin-top: 5px;
  min-height: 485px;
}

.VendorDetailsDiv thead tr,
.VendorDetailsDiv thead th {
  font-size: 16px;
  font-weight: 400;
}

.VendorDetailsDiv .cancelbtn {
  background: darkgrey;
  margin-right: 2%;
  text-transform: capitalize;
  color: #000;
  width: 100px;
}

.VendorDetailsDiv .savebtn {
  background: #82af3f;
  text-transform: capitalize;
  color: #fff;
  width: 100px;
}

/* .VendorDetailsDiv tbody tr {
  box-shadow: 0 0 0.5px #9e9e9e;
  background: white;
} */

.VendorDetailsDiv tr,
.VendorDetailsDiv th,
.VendorDetailsDiv td {
  border-bottom: 0px solid rgba(224, 224, 224, 1);
}

.MuiTablePagination-select {
  padding-left: 8px !important;
  padding-right: 24px !important;
  z-index: 0 !important;
}

.MuiTablePagination-caption {
  font-size: 1.5rem !important;
}

.VendorDetailsDiv .MuiInputBase-input {
  padding-bottom: 5px !important;
}

.MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  z-index: 300;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

/* .table thead th {
  border-bottom: 0px !important;
  height: 60px !important;
  background-color: #e7e7e7aa !important;
  padding: 0px 25px !important;
} */

.VendorDetailsDiv tbody tr {
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  border-radius: 2px !important;
  position: relative;
  top: 15px;
  height: 60px;
}

.VendorDetailsDiv tbody tr:hover {
  background-color: rgb(207, 205, 205) !important;
  box-shadow: 5px 5px 5px 0px #e0e0e0, inset 4px 4px 15px 0px #e4e4e4,
    -1px 2px 0px 2px rgba(224, 224, 224, 0);
  border-radius: 2px !important;
}

.VendorDetailsDiv thead tr {
  border-bottom: 0px !important;
  font-weight: bold !important;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}



/* .table > tbody > tr > th:first-child {
  padding-left: 20px;
} */

.table td,
.table th {
  border-top: 0px solid #dee2e6 !important;
}

.tableeye_icon {
  color: #5377ee;
  font-size: 20px !important;
  margin-right: 7px;
  cursor: pointer;
}

.tableedit_icon {
  color: #83af40;
  font-size: 20px !important;
  margin-right: 7px;
  cursor: pointer;
}

.tabledelete_icon {
  color: #ee534f;
  font-size: 20px !important;
  cursor: pointer;
}

.tableupload_icon {
  color: #83af40;
  font-size: 20px !important;
  margin-right: 7px;
  cursor: pointer;
}

.tableupload_icon svg {
  width: 18px;
  height: 18px;
  fill: #83af40;
}

.tableupload_icon {
  padding-top: 3px;
}

.group_grandtotal {
  display: flex;
  align-items: flex-end;
  border: 1px solid;
  background-color: #83af40;
  font-size: 18px;
  padding: 15px;
  width: 235px;
  color: white;
  float: right;
  margin-top: 50px;
  margin-right: 15px;
}

.spinner_align {
  padding: 260px 20px 20px 44%;
}

.spinner_align span i {
  background-color: #83af40;
}

.noFoundIconCenter {
  text-align: center !important;
}

.noFoundIconCenter img {
  width: 100px;
  margin-top: 40px;
  /* margin-right: 100px; */
}

.noFoundIconCenter div {
  /* margin-right: 100px; */
}

.VendorDetailsDiv .noFoundIconCenter {
  border: 1px solid #e2dddd !important;
  padding-bottom: 85px;
}

.tbl_tick_icon {
  font-size: 20px !important;
  font-weight: bold;
  fill: #86b149 !important;
  stroke-width: 2px;
  stroke: #86b149 !important;
}

/* for revenue mode of payment css  */
.mode_of_pay {

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  margin: 10px 0px 0px 320px;
}

.line_payment {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 325px;
}

.inner_line {
  width: 13vw;
  border: 0.1px solid #333;
  margin: 3px 0px 0px 0px;
}

/*
.tableWrapper table thead tr th .MuiTableSortLabel-icon
{
  display: none !important;
} 
/* 
.tableWrapper table thead tr th:hover .MuiTableSortLabel-icon
{
  display: block !important;
} */

.ticket_reopen,
.ticket_resolved {
  border: 1px solid black;
  padding: 5px 10px;
  margin: 0px 5px 0px 5px;
  border-radius: 13px;
  color: black;
  cursor: pointer;
}

.ticket_reopen:hover,
.ticket_resolved:hover {
  background-color: #83af40;
  border: 1px solid #83af40;
  color: white;
}

.reopen_status {
  background-color: #83af40 !important;
  border: 1px solid #83af40 !important;
  color: white !important;
  pointer-events: none;
}

.ticket_reopen,
.ticket_resolved {
  border: 1px solid black;
  padding: 5px 10px;
  margin: 0px 5px 0px 5px;
  border-radius: 13px;
  color: black;
  cursor: pointer;
}

.ticket_reopen:hover,
.ticket_resolved:hover {
  background-color: #83af40;
  border: 1px solid #83af40;
  color: white;
}

.reopen_status {
  background-color: #83af40 !important;
  border: 1px solid #83af40 !important;
  color: white !important;
  pointer-events: none;
}

.HeadingButton {
  background-color: #510f30;
  border-radius: 18px;
  color: white !important;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading_button_value {

  /* border-radius: 12px;
  padding: 7px 8px; */
  /* padding: 7px 15px; */
  /* border-radius: 20px; */
  display: flex;
  align-items: baseline;
  gap: 2px;
  white-space: nowrap;
}

.rsheaddingbutton {
  padding: 5px 7px;
  border-radius: 20px;
}

.buttonHeadtitle {
  border-bottom: 0px !important;
  height: 60px !important;
  background-color: #fff !important;
  box-shadow: none !important;
}

.MuiTablePagination-caption {
  font-size: 13px !important;
}

.MuiSelect-root {
  font-size: 13px !important;
}


.table thead th {
  height: 38px !important;
  background-color: #2F887C !important;
  text-align: left;
  padding: 0px !important;
  padding-left: 8px !important;
}