.home_header {
    top: 0;
    /* height: 80px; */
    height: 80px;
    width: 100%;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    box-shadow: #00000029 0 3px 6px;
    position: fixed;
    z-index: 1;
}


.header_right_column {
    float: right;
}

.rejectimage {
    width: 100px !important;
    margin-top: -7px;
    margin-right: 2px;
}

.footer {
    position: fixed;
    /* z-index: 100; */
    z-index: 999;
    left: 0;
    bottom: 0;
    /* position: sticky; */
    bottom: 0px;
    width: 100%;
    /* height: 77px; */
    height: 70px;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    text-align: center;
    /* padding: 12px; */
    padding: 8px;
    box-shadow: #42596527 0 1px 20px;

}


.logo_images {
    margin: 10px 5px;
    height: 56px;
    width: 130px;
}

.logo_images_aprove {
    margin: 10px 20px;
    height: 56px;
    width: 130px;
}


/* .header_row {
    background-color: aqua;
} */

.header_Logo {
    background-color: aquamarine;
}



/* ============= */
.flex_grow_1 {
    flex-grow: 1;
}

.profile_img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 5px;
    /* margin: 5px 10px 5px 5px; */
}

.profile_img_aprove {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 5px;
    position: relative;
    z-index: 1;
    margin: 5px 20px;
    cursor: pointer;
}


/* ============= */

.head_right_content {
    margin-right: 0 !important;
}

.profile_name_header {
    /* color: var(--app-theme); */
    color: var(--app-theme);
    font-size: 18px;
    margin: 12px 0 0 0;
    /* font-weight: 600; */
}

.username {
    margin-top: 0 !important;
}

.fa-bell {
    margin-top: 20px;
}

.row_content_ {
    margin: 0 !important;
}

.row_content_aprove {
    margin: 0 !important;
    display: flex;
    align-items: center;
    height: 70px;
}

.dropdown_button {
    margin-top: 22px;
}

.dropdown_button:hover {
    background-color: none !important;
}

.dropdown_button:active {
    background-color: none !important;
}


.support_images {
    height: 32px !important;
    width: 32px !important;
    margin: 27px 0 0 15px;
    cursor: pointer;
}

.fix_dropdown {
    margin: 15px 0;
}

.fix_dropdown_aprove {
    display: flex;
    align-items: center;
    margin-left: 30px;
}

.header_icons {
    display: flex;
    flex-direction: row;
}

.MuiInputBase-inputSizeSmall,
.MuiSvgIcon-fontSizeMedium {
    color: var(--app-sub-header) !important;
    border: var(--app-sub-header) !important;
}

.profile_text {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0px 20px 0px 40px;
}

.profile_text_aprove {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0px 20px 0px 40px;
}


.header_right_part {
    display: flex;
    justify-content: end;
}

.verification_main_div {
    /* margin-top: 50px; */
    margin-top: 110px;
    text-align: center;
}

.step_head_img {
    /* width: 75%;
    height: 150px;
    margin-top: 2rem;
    cursor: pointer;
    position: relative;
    z-index: 9; */
    width: 8vw;
    height: 15vh;
    margin-top: 2rem;
    cursor: pointer;
    position: relative;
    z-index: 9;
    border: 2px solid var(--app-theme);
    padding: 10px;
    border-radius: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    background-color: white;
}

.stepper_line_show .MuiStepIcon-completed {
    fill: #510f30 !important;
    height: 40px !important;
    width: 40px !important;
    margin-top: -2px;
}

.stepper_line_show .css-95m0ql-MuiStepConnector-line {
    display: block;
    border-color: #510F30 !important;
    border-top-style: solid;
    border-top-width: 3px !important;
}

.stepper_line_show .css-z7uhs0-MuiStepConnector-line {
    display: block;
    border-color: #510F30 !important;
    border-top-style: solid;
    border-top-width: 3px !important;
}

.stepper_line_show .MuiStepConnector-line {
    display: block;
    border-color: #510F30 !important;
    border-top-style: solid;
    border-top-width: 3px !important;
}

/* .css-z7uhs0-MuiStepConnector-line */
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    height: 40px !important;
    width: 40px !important;
    margin-top: -7px;
    color: #510f30 !important;
}

.stepper_line_show .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root .Mui-completed {
    height: 40px !important;
    width: 40px !important;
    margin-top: -7px;
    color: var(--app-theme) !important;
}

.stepper_line_show .css-4ff9q7 .Mui-completed {
    color: var(--app-theme) !important;
    height: 40px !important;
    width: 40px !important;
    margin-top: -7px !important;
}

.stepper_line_show .MuiSvgIcon-fontSizeMedium {
    color: var(--app-theme) !important;
    height: 40px !important;
    width: 40px !important;
    margin-top: -7px !important;
    /* border: #CCCCCC !important; */
}

/* =================== */
.dash_stepper .Mui-disabled svg,
.dash_stepper .Mui-active svg {
    border: 1px solid grey !important;
    border-radius: 100%;
    height: 40px !important;
    width: 40px !important;
    margin-top: -7px;
}

.Mui-disabled circle,
.Mui-active circle {
    fill: white;
}

.play_img {
    position: absolute;
    cursor: pointer;
    /* top: 33vh; */
    /* top: 36vh; */
    /* top: 50vh; */
    /* margin-left: -0.8vw; */
    margin-left: calc(1rem + -35px);
    bottom: 0px;
    /* z-index: -1; */
}

.disable_card {
    opacity: 0.5;

}

.notification_not_icon {
    width: 31px;
    height: 31px;
    margin-top: 28px;
}

.waiting_class {
    width: 100%;
    padding-top: 1%;
    text-align: end;
    padding-right: 150px;
    font-size: 16px;
    color: #510F30;
}

.waiting_class1 {
    width: 100%;
    padding-top: 1%;
    text-align: end;
    padding-right: 160px;
    font-size: 16px;
    color: #510F30;
}

.decline_msg_box {
    border-radius: 12px;
    border: 1px solid #510f30;
    width: 70%;
    height: 175px;
    display: flex;
    padding: 8px;
    font-size: 12px;
}

.congrats_text {
    width: 100%;
    padding-top: 1%;
    text-align: end;
    padding-right: 8.5%;
    font-size: 16px;
    color: #510F30;
}

.three_head {
    color: #510f30;
    margin-bottom: 0px !important;
}

.three_step_head {
    padding: 10px 0px;
    box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, .14), 0 0px 7px 0 rgba(0, 0, 0, .12) !important;
    color: #510F30;
    font-weight: 400 !important;
    width: 95%;
    margin: auto;
    margin-bottom: 40px !important;
}

.hand_icon {
    height: 20px;
    width: 30px;
}

.click_div {
    display: flex;
    justify-content: end;
    padding-right: 30px;
    padding: 0px 25px 0px 0px;
}

.click_div p {
    color: #510F30;
    font-size: 9px;
    margin-top: 0.8%;
}

.start_button {
    padding: 3px 19px 3px 19px;
    border-radius: 20px;
    font-size: 16px;
    color: white;
    background-color: #510f30;
    border-color: black;
    font-family: titillium web !important;
    font-weight: 500 !important;
}

.notification_images {
    cursor: pointer;
    width: 32px;
}