/* -------------------- New FLow ---------------------------- */

.login_page {
  background: #ffffff;
  height: 100vh;
  width: 100%
}

.login_BG {
  background-image: url('../../assets/Doctor_Images/Login_Back.jpeg');
  border-radius: 25px;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.login_input_card {
  position: absolute;
  top: 48%;
  left: 49%;
  transform: translate(-50%, -50%);
}

.welcome_name {
  font-size: 25px;
  font-family: Gilroy;
  color: #000;
  font-weight: bold;
  margin: 0px;
}

.doctor_name {
  font-size: 35px;
  font-family: Gilroy;
  color: #000;
  font-weight: bold;
}

.login_name {
  font-size: 14px;
  font-family: Gilroy;
  color: #000;
  font-weight: 600;
}

.email_container {
  position: relative;
  padding-top: 10px;
}

.password_container {
  position: relative;
  padding-top: 18px;
}

.email_input_label {
  font-size: 16px;
  font-family: Poppins;
  color: #000;
  font-weight: 600;
  margin-bottom: 5px;
}

.password_input_label {
  font-size: 16px;
  font-family: Poppins;
  color: #000;
  font-weight: 600;
  margin-bottom: 5px;
}

.email_input {
  padding: 10px 230px 10px 20px;
  border-radius: 7px;
  border: 1px solid var(--app-theme);
  outline: none;
  cursor: pointer;
  height: 65px;
}

.email_input::placeholder{
  font-size: 13px;
  font-family: Poppins;
}

.password_input {
  padding: 10px 230px 10px 20px;
  border-radius: 7px;
  border: 1px solid var(--app-theme);
  outline: none;
  cursor: pointer;
  height: 65px;
}

.password_input::placeholder{
  font-size: 13px;
  font-family: Poppins;
}

.login_BG .email_box_icon {
  right: 15px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 61px;
  cursor: pointer;
}


.login_BG .MuiInput-underline:after {
  transition: none !important;
  pointer-events: none;
  border: 1px solid var(--app-theme) !important;
  pointer-events: none;
  height: 45px;
  width: 130%;
  background-color: #fff;
}

.login_BG .MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  transition: none !important;
  border: 1px solid var(--app-theme) !important;
  pointer-events: none;
  height: 45px;
  width: 130%;
  background-color: #fff;
}

.login_button{
  padding: 20px 195px;
  border: 1px solid var(--app-theme);
  background: var(--app-theme);
  color: #fff;
  border-radius: 10px;
  font-family: Gilroy;
}

.login_forgot{
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

.login_forget_button{
  color: #000;
  font-size: 13px;
  font-weight: 600;
  font-family: Poppins;
  padding-top: 15px;
}

.login_remainder_button{
  color: #000;
  font-size: 13px;
  font-weight: 600;
  font-family: Poppins;
  padding-top: 15px;
  padding-left: 8px;
}

.remainder_button{
  display: flex;
}

.remainder{
  height: 50px;
  width: 20px;
  color: var(--app-theme);
}

.remainder:checked {
  accent-color: var(--app-theme); /* Change color when checked */
}

.login_button_container {
  padding-top: 25px !important;
}


@media only screen and (max-width: 680px) {
  
  .login_input_card {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
}




















.password_container {
  new_imp_text {
    position: relative !important;
    top: -15px !important;
  }
}

.pharmacy_login_container .pharmacy_main_container {
  width: 70%;
  height: auto;
}

.pharmacy_login_container .inbox_icon_div {
  width: 20px;
  height: 20px;
  position: absolute;
}

.pharmacy_login_container .inbox_icon {
  width: 20px;
  height: 20px;
}

.pharmacy_login_container .pharmacy_grid_container {
  width: 100%;
  height: auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pharmacy_login_container .logo_image {
  width: 90%;
  height: 90%;
}

.pharmacy_login_container .logo_image {
  width: 90%;
  height: 90%;
}

.pharmacy_login_container .logo_image_div {
  width: 100px;
  height: 100px;
}

.logo_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.pharmacy_login_container .logo_div {
  width: 100px;
  height: auto;
}

.pharmacy_login_container .pharmacy_paper_div {
  align-items: center;
  justify-content: center;
}

.pharmacy_login_container .pharmacy_image_grid {
  width: 100%;
  height: 100%;
  display: flex;
}

.pharmacy_login_container .pharmacy_image {
  width: 100%;
  height: 100vh;
  position: relative;
}

.pharmacy_login_container .pharmacy_image_login {
  width: 100%;
  height: 100%;
}

.pharmacy_login_container .pharmacy_image_div {
  width: 100%;
  height: 100%;
}

.pharmacy_login_container .lock_icon_container {
  width: 100%;
  height: 100%;
  top: 170px;
  left: 481px;
  position: absolute;
}

.pharmacy_login_container .lock_icon_div {
  width: 20px;
  height: 20px;
}

.pharmacy_email_container {
  border: none !important;
  position: relative;
  top: 2pxsss;
}

.pharmacy_login_container .lock_icon {
  width: 100%;
  height: 100%;
}

.pharmacy_login_container .password_container {
  font-family: "Avenir-Black" !important;
  /* margin-top: 10px !important; */
  position: relative !important;
  top: -15px !important;
}

.pharmacy_login_container .main_containerdiv {
  height: 100% !important;
  width: 100% !important;
}

.pharmacy_login_container .pharmacy_image {
  width: 100%;
  box-shadow: 0 0 2px 2px #e8e8e8;
  background-color: #F9E1D9 !important;
  display: flex;
  /* justify-content: center; */
}

.login_new_lady_img {
  height: 80%;
  position: fixed;
  left: 34%;
}

.lab_login_text {
  display: flex;
  font-size: 4.5vh;
  margin-top: 28vh;
  position: fixed;
  color: #510F30;
  font-weight: 600;
  left: 3%;
  flex-direction: column;
  gap: 10px;
}

.tom_Journey {
  align-items: center;
  background-color: #fff;
  background: #510f30;
  border: none;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: Roboto-Regular !important;
  font-weight: 400 !important;
  font-size: 16px;
  height: 55px;
  justify-content: center;
  width: 44%;
  margin-top: 12px;
  gap: 5px;
}

.numweb_contee {
  display: flex;
  align-items: flex-end;
}

.pharmacy_login_container .Welcometext {
  color: var(--app-theme) !important;
  /* font-size: 2rem !important; */
  font-size: 36px !important;
  font-weight: 700 !important;
  font-family: 'Roboto-Regular' !important;
}

.pharmacy_Welcometext-container {
  height: 15%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: flex-end;
  font-family: "Roboto";
}

.pharmacy_login_container .Submit {
  color: rgb(80, 174, 89) !important;
  text-decoration: none !important;
  cursor: pointer;
  font-size: 15px;
}

.pharmacy_login_container .submit_container {
  text-align: right;
  margin-top: 20px;
}

.pharmacy_login_container .cancelbutton {
  text-decoration: none !important;
  cursor: pointer;
  font-family: "Roboto-Regular" !important;
  color: #ff0068 !important;
  font-weight: 600 !important;
}

.pharmacy_login_container .pharmacy_image_container {
  width: 100%;
  height: 100%;
  display: flex;
}

.pharmacy_login_container .parent {
  position: relative;
}

.cancel_container {
  margin-top: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-bottom: 10px;
}

.cancelbutton {
  color: #ff0068 !important;
}



.cancelbutton:hover {
  cursor: pointer !important;
}

.clinic_login_container ::placeholder {
  font-size: 10px;
  font-family: "Avenir-Black" !important;
}

.pharmacy_login_container .login {
  align-items: center;
  background-color: #fff;
  background: #510f30;
  /* background: linear-gradient(180deg, #510f30, #b33676); */
  border: none;
  border-radius: 50px;
  border-radius: 34px;
  /* box-shadow: 0 10px 6px #00000030; */
  background: linear-gradient(180deg, #510f30);
  box-shadow: 0 6px 6px rgba(0, 0, 0, .18823529411764706);
  color: #fff;
  cursor: pointer;
  display: flex;
  /* font-family: Avenir-Black; */
  font-family: Roboto-Regular !important;
  font-weight: 400 !important;
  font-size: 1.5rem;
  /* height: 50px; */
  height: 55px;
  justify-content: center;
  margin: auto;
  width: 35%;
}

.pharmacy_login_container .label+.MuiInput-formControl {
  margin-top: 22px !important;
}

.pharmacy_login_container button:focus {
  outline: none !important;
}

@media only screen and (max-width: 768px) {
  .pharmacy_login_container .pharmacy_image {
    display: none;
    position: relative;
  }

  .pharmacy_login_container .pharmacy_main_container {
    width: 100%;
    height: auto;
    /* margin-top: 177px; */
  }

  .pharmacy_login_container .pharmacy_grid_container {
    width: 100%;
    height: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pharmacy_login_container .pharmacy_text {
    top: 14%;
    position: absolute;
  }
}

@media only screen and (max-width: 959px) {
  .pharmacy_login_container .pharmacy_image_grid {
    display: none;
  }

  .pharmacy_login_container .pharmacy_grid_container {
    width: 100%;
    height: 100vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pharmacy_login_container .pharmacy_main_container {
    width: 70%;
    height: auto;
  }

  .pharmacy_login_container .pharmacy_main_container {
    width: 45%;
  }

  .pharmacy_login_container .Welcometext {
    font-size: 3rem !important;
  }
}

@media only screen and (max-width: 1024px) {
  .trainer_text {
    margin-top: -169px;
  }
}

@media only screen and (max-width: 1058px) {
  .pharmacy_login_container .Welcometext {
    font-size: 2rem !important;
  }
}

.pharmacy_login_container .MuiInputBase-root {
  font-family: "Avenir-Black" !important;
  /* margin: 0px; */
}

.pharmacy_login_container .MuiInputBase-input {
  font-size: 14px !important;
  height: 2.7rem !important;
  font-family: "Roboto" !important;
  padding: 6px 0 4px !important;
}

.pharmacy_login_container .MuiInputLabel-formControl {
  /* font-size: 1.25rem !important; */
  font-size: 14px !important;
  font-weight: 400px !important;
  top: 10px !important;
  font-family: "Avenir-Black" !important;
}

.pharmacy_login_container .MuiInput-underline:after {
  transition: none !important;
  /* border-bottom: none !important; */
  pointer-events: none;
}

.pharmacy_login_container .MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  transition: none !important;
  pointer-events: none;
  /* border-bottom: 2px solid #000000ed !important; */
}

.pharmacy_login_container .MuiInputLabel-root.Mui-focused {
  color: grey !important;
  font-family: "Avenir-Black" !important;
  border-bottom: red !important;
}

.pharmacy_login_container .MuiFormControl-root {
  width: 100% !important;
  /* margin-left: 46px; */
  /* margin-right: 46px; */
  /* border-bottom: 1px solid #333333b0 !important; */
}

.pharmacy_login_container .MuiFormLabel-root {
  /* line-height: 0!important;*/
  font-family: "Avenir-Black" !important;
}

.pharmacy_login_container .inbox_icon {
  width: 20px;
  height: 20px;
}

.pharmacy_text {
  position: absolute;
  /* top: -310px; */
  top: -285px;
  left: -628px;
  align-items: center;
  font-size: 3rem;
  color: white;
  font-family: "Avenir-Black" !important;
  transform: rotate(-90deg);
  /* right: 617px; */
  right: 583px;
  text-shadow: 0px 3px 6px #000000e6;
}

.logineye_icon {
  width: 30px;
  height: 30px;
}

.password_container .MuiIconButton-root {
  padding: 6px !important;
  position: relative;
  left: 6px;
}

.reject_btn {
  background: #d11a2a 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 5px #00000033 !important;
  border-radius: 25px !important;
  opacity: 1 !important;
  color: #fff !important;
  font-size: 13px !important;
  padding: 6px 16px !important;
  font-weight: 600 !important;
  font-family: "Roboto" !important;
  text-transform: capitalize !important;
  margin-right: 10% !important;
}

.accept_btn {
  background: var(--app-theme) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 5px #00000033 !important;
  border-radius: 25px !important;
  opacity: 1 !important;
  color: #fff !important;
  font-size: 16px !important;
  padding: 6px 25px !important;
  font-weight: 500 !important;
  font-family: "Roboto" !important;
  text-transform: capitalize !important;
}

.approve_popu_main_div {
  width: 80%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-right: auto;
  margin-left: auto;
}

.approval_img_container {
  margin-bottom: 0px;
}

.approval_img_container .ant-upload.ant-upload-select-picture-card {
  background-color: #fff !important;
  border: 0px !important;
  width: 90px !important;
  border-radius: 50% !important;
  border: none !important;
  position: relative;
  height: 90px !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12) !important;
}

.approval_img_container .ant-upload {
  padding: 0px !important;
}

.m-signature-pad {
  width: 370px;
  border: 1px solid #fff;
  /* margin: auto; */
  margin: -10px;
}

.contract_approve_btn_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signature_icon {
  color: #86b149 !important;
  font-size: 2rem !important;
  cursor: pointer;
}

.contract_approve_btn_div button {
  background-color: var(--app-theme);
  border-radius: 50px;
  padding: 6px 25px;
  color: white;
  border-color: var(--app-theme);
  font-size: 16px !important;
  font-weight: 500;
  margin-top: 10px;
}

.contract_approval_popup~div {
  display: none !important;
}

.pic_align {
  position: absolute;
  bottom: 0px;
  right: 7px;
  top: 60px;
  background: #d9d9d9;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
}

.accept_btn_contract {
  background: var(--app-theme) no-repeat padding-box !important;
  border-color: var(--app-theme) !important;
}

.approve_popu_main_div label,
.approve_popu_main_div .formdiv .labeltxt {
  font-size: 15px !important;
  color: rgb(102, 102, 102) !important;
  font-weight: 500 !important;
}

.contatct_approve_head {
  color: var(--app-theme) !important;
  font-family: titillium web !important;
  text-transform: uppercase !important;
}

.approval_check_popup .MuiDialog-paperFullWidth,
.signature_popup .MuiDialog-paperFullWidth {
  min-width: 30% !important;
  max-width: 30% !important;
  width: auto !important;
}

.titillium_font {
  font-family: titillium web !important;
}

.contract_alert_popup .MuiDialog-paperFullWidth {
  min-width: 20% !important;
  max-width: 25% !important;
  width: auto !important;
}

.reject_btn {
  background: #f45959 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 5px #00000033 !important;
  border-radius: 25px !important;
  opacity: 1 !important;
  color: #fff !important;
  font-size: 16px !important;
  padding: 6px 25px !important;
  font-weight: 500 !important;
  font-family: "Roboto" !important;
  text-transform: capitalize !important;
  margin-right: 5% !important;
}

.approve_popu_main_div .approval_img_container .ant-upload-picture-card-wrapper {
  justify-content: left !important;
}

.m-signature-pad--footer .clear {
  display: none;
}

.approval_add_icon {
  font-size: 1rem !important;
  color: #bfbfbf !important;
}

.contract_details {
  display: flex;
  padding-left: 15px;
}

.upload-img-circle {
  border-radius: 50% !important;
  width: 92px !important;
  height: 92px !important;
}

.profile_name_details {
  margin: 30px 1px 1px 21px;
  color: black;
  font-size: 18px;
  font-weight: 400;
}

.policies_details {
  color: #510f30;
}

.view_terms_policies {
  text-align: center;
  color: #00E8D2;
}

.terms_and_policies {
  text-align: center;
  margin-top: 22px !important;
}


.terms_and_policies_start {
  text-align: start;
  padding-left: 10px !important;
  margin-top: 22px !important;
}

.terms_and_policies_end {
  text-align: end;
  padding-right: 10px !important;
  margin-top: 22px !important;
}

.contract_form_label {
  display: flex;
}
.contract_form_label_sign {
  display: flex;
  justify-content: center;
}

.signature_details {
  border: 1px solid #510f30;
  padding: 10px;
  width: 112px;
  text-align: center;
  margin-top: -14px;
}

.sig_container {
  border: 1px solid #510f30;
  height: 40px;
  width: 32%;
  display: flex;
  margin: 5px 117px;
}

.sig_img {
  width: 100%;
  /* height: 43px; */
  height: 38px;
}

.remove_container {
  background-color: #ff0068;
  color: #ffffff;
  /* width: fit-content; */
  display: flex;
  border-radius: 25px;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 10px;
  padding: 1px 5px;
  margin: -8px -8px 0 0;
}

.vendor_signature_Tom_signature {
  color: #510f30;
}

.tom_signature {
  color: #510F30;
}

.tom_logo {
  width: 75%;
  height: 36px;
  margin-top: 10px;
}

.accept_btn {
  background: #510f30 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 5px #00000033 !important;
  border-radius: 25px !important;
  opacity: 1 !important;
  color: #fff !important;
  font-size: 16px !important;
  padding: 6px 28px !important;
  font-weight: 500 !important;
  font-family: 'Roboto-Regular' !important;
  text-transform: capitalize !important;
}

.contract_accept_btn {
  background: #510f30 !important;
  font-size: 16px !important;
  padding: 5px 27px !important;
  font-weight: 500 !important;
}


.reject_btn_one {
  background: #ff0068 0% 0% no-repeat padding-box !important;
  border: #ff0068 !important;
  box-shadow: 0px 2px 5px #00000033 !important;
  border-radius: 25px !important;
  opacity: 1 !important;
  color: #fff !important;
  font-size: 16px !important;
  padding: 6px 28px !important;
  font-weight: 500 !important;
  font-family: 'Roboto-Regular' !important;
  text-transform: capitalize !important;
  margin-right: 5% !important;
}

/* .pharmacy_email_container{
  new_imp_text{
    margin: 0px;
  }
} */
.login_bigy {
  position: relative;
  top: 8px;
}



/* .pushNotification_body{

  display: flex;
    position: absolute;
    top: 24px;
    left: calc(100vw - 70%);
    background-color: #fff;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    padding: 10px;
    width: 520px;
    z-index: 9999999;
    margin: auto;
    border-radius: 8px;
}
.pushNotification_body .logo {
  width: 120px;
  height: auto;
  
} */
/* .pushNotification_body .logo img{
  width: 100%;
} */
/* .pushNotification_body .pushNotification_content{
  text-align: left;
  padding: 14px;
} */


/* .pushNotification_body .pushNotification_content{
	text-align: left;
	padding: 0px 0px 0px 15px !important;
	font-size: 16px;
  } */

  /* .notification_img_container {
    margin: 3px;
    width: 20%;
  }

  .notification_imgs{
    display: flex;
    margin: 3px;
    width: 107%;
    height: 70%;
    margin-top: 10px;
  } */

  /* .pushNotification_content{
    text-align: left;
    padding: 14px;
    font-size: 16px;
    } */
/* 
    .notification_text_head {
      margin: 5px 10px;
      font-size: 16px !important;
    }

    .notification_text_body {
      font-size: 14px !important;
      margin: 5px 10px;
    } */