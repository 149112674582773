/* -------------------- New FLow ---------------------------- */

.dashboard_header {
  top: 0;
  width: 100%;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  box-shadow: #00000029 0 3px 6px;
  position: fixed;
  z-index: 1;
}

.dashboard_header_row {
  margin: 0 !important;
  display: flex;
  align-items: center;
}

.dashboard_header_content {
  display: flex;
  align-items: center !important;
}

.dashboard_header_logo {
  margin: 15px;
  height: 35px;
  width: 170px;
}

.dashboard_header_dropdown {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

/* .dashboard_header_dropdown_button {
  margin-top: 22px;
} */

.dashboard_header_dropdown_button:hover {
  background-color: none !important;
}

.dashboard_header_dropdown_button:active {
  background-color: none !important;
}

.dashboard_header_profile_part {
  display: flex;
  justify-content: end;
}

.dashboard_header_icons {
  display: flex;
  flex-direction: row;
}

.dashboard_header_home_icon {
  position: relative;
}

.header_home_image {
  height: 32px !important;
  width: 32px !important;
  margin: 11px 5px 0 5px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.header_home_image:hover {
  transform: scale(1.08);
}

.header_notification_image {
  cursor: pointer;
  width: 32px;
}

.notification_image_header {
  background-color: #8ebcb575;
  width: 32px;
  height: 32px;
  margin-top: 10px;
  cursor: pointer;
  position: relative;
  border-radius: 30px;
}

.notification_icon_image {
  width: 17px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 6px;
  left: 7px;
}

.header_profile_image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 9px 0px 0px 10px;
  cursor: pointer;
  border: 1px solid var(--app-theme);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.header_profile_image:hover {
  transform: scale(1.05);
  box-shadow: 0 0 0 2px var(--app-theme);
}

.profile_text_header {
  margin: 13px 10px 0px;
  font-weight: 600;
  font-family: Segoe UI;
}

.profile_text_name_header {
  color: #000;
  font-size: 18px;
  margin-top: 0 !important;
}

@media only screen and (min-width: 655px) and (max-width: 760px) {
  .dashboard_header_logo {
    margin: 10px 5px;
    height: 33px;
    width: 70px;
  }

  .dashboard_header_dropdown {
    display: flex;
    align-items: center;
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 655px) {
  .dashboard_header_logo {
    margin: 10px 5px;
    height: 33px;
    width: 70px;
  }

  .dashboard_header_dropdown {
    display: flex;
    align-items: center;
    margin-left: 0px !important;
  }

  .dashboard_header_dropdown .css-q8hpuo-MuiFormControl-root {
    width: 140px !important;
  }
}

.credit_point_img {
  width: 160px;
  height: 120px;
}
.credit_point_imgs {
  height: 40px;
}
.credit_point_card_one {
  width: 250px !important;
  padding: 15px;
  border-radius: 10px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
}
.credit_point_card_two {
  width: 170px !important;
  padding: 10px;
  border-radius: 10px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
}
.credit_point_row_one {
  padding-top: 30px;
  display: flex;
  justify-content: space-around;
}
.credit_point_row_two {
  padding-top: 13px;
  display: flex;
  justify-content: space-evenly;
}
.credit_point_name {
  color: var(--app-theme);
  font-size: 25px;
  margin: 0;
}
.credit_point_member {
  color: #ebeb13;
  font-size: 30px;
  margin: 0;
}
.credit_point_wallet {
  font-weight: bolder;
  font-size: 18px;
  margin: 0;
}
.credit_point_kwd {
  margin: 0;
  color: var(--app-theme);
}
.credit_point_earned {
  margin: 0;
  color: var(--app-theme);
}
.credit_point_card_three {
  text-align: center;
  padding: 25px;
  margin-left: 62px;
  border-radius: 10px !important;
  border: 1px solid #c3c3c3;
  box-shadow: 0px 3px 3px #8a8a8a !important;
}
.credit_point_card_four {
  text-align: center;
  padding: 25px;
  border-radius: 10px !important;
  border: 1px solid var(--app-theme);
  box-shadow: 0px 0px 39px #84af408f !important;
}
.credit_point_kwd span {
  font-size: 25px;
}
.credit_point_row_three {
  padding-top: 10px;
  display: flex;
  justify-content: center;
}
.credit_point_images {
  width: 80px;
  height: 60px;
}
.credit_point_card_five {
  width: 100%;
  margin: 20px 75px;
  padding: 20px 10px 0px 10px;
  border: 1px solid #707070;
  border-radius: 10px !important;
}
.credit_point_mobile {
  font-size: 60px;
}

.credit_point_card_name {
  font-weight: bolder;
}
.credit_point_date {
  margin: 0;
  color: #959595;
}
.profile_model {
  position: fixed !important;
  z-index: 1300 !important;
  right: 0 !important;
  bottom: 10vh !important;
  top: 0 !important;
  left: unset !important;
  height: fit-content !important;
}
.profile-image-container {
  display: flex;
  justify-content: center;
}
.profile-image-container img {
  height: 170px;
  width: 170px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: fill;
}
.profile-name {
  font-size: 23px;
  margin: 1rem 0rem;
  color: var(--app-theme);
  font-family: Poppins !important;
}

.profile-line {
  margin-right: 0.7rem;
  border-right: 1px solid #707070;
  height: 100%;
}

.profileimg-subheader {
  text-align: end;
}
.profileimg-subheader p {
  color: var(--app-theme);
  font-size: 15px;
}
.profileimg-value {
  text-align: start;
}
.profileimg-value p {
  color: #000;
  font-size: 15px;
}
.profile-edit-button {
  border: none;
  color: white;
  padding: 2px 20px;
  font-size: 14px;
}
.profile-edit-button:hover {
  color: white !important;
}
.profile-editicon {
  height: 14px;
  padding-right: 15px;
  padding-bottom: 1px;
}
.edit-parent-dvsn {
  display: flex;
  justify-content: center;
}
.edit-child-dvsn {
  width: fit-content;
  background: #2f887c;
  border-radius: 39px;
  margin-left: 10px;
  padding: 5px;
  cursor: pointer;
  color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.edit-child-dvsn:hover {
  transform: scale(1.05);
  box-shadow: rgba(99, 99, 99, 0.3) 0px 4px 12px 0px;
  background: #1f665b; /* Change to desired hover color */
}
.logout-child-dvsn {
  width: fit-content;
  background: #2f887c;
  border-radius: 39px;
  padding: 5px;
  margin-right: 10px;
  cursor: pointer;
  color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.logout-child-dvsn:hover {
  transform: scale(1.05);
  box-shadow: rgba(99, 99, 99, 0.3) 0px 4px 12px 0px;
  background: #1f665b; /* Change to desired hover color */
}

.terms-policy {
  font-size: 11px;
  font-weight: 600;
  text-decoration: underline;
  margin-top: 1rem;
  cursor: pointer;
}
.prfile_container {
  margin: 1rem 1rem;
}
