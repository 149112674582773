.sub_header_body {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header_img_card img {
    width: 30px;
}

.sub_header_image {
    display: flex;
    gap: 9px;
}

.header_img_card {
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px #00000029;
    font-size: 12px;
    text-align: center;
    padding: 3px 7px;
}

.header_img_card p {
    color: #666666;
    font-size: 13px;
    margin-bottom: 0;
}

.content_view {
    /* padding: 0 12px !important; */
    padding: 15px 12px !important;
}

.revenue_profile_div {
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.revenue_name_div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.revenue_profile_img img {
    width: 102px;
    height: 115px;
}

.revenue_doc_name {
    font-size: 17px;
    color: #000000;
    font-weight: 600;
}

.revenue_profile_lab {
    font-size: 14px;
    color: #707070;
}

.revenue_download_div {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: end;
}

.revenue_download_div img {
    height: 29px;
    width: 27px;
}

.revenue_download_div div {
    font-size: 15px;
    color: #333333;
}

.revenue_total_revenue {
    width: 190px;
    height: 45px;
    background: #82AE3F;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-top: 15px;
    border-radius: 5px;
}

.revenue_total_revenue span {
    margin-left: 5px;
    font-size: 12px;
}

.revenue_note {
    /* margin-top: 20px; */
    text-align: end;
    font-size: 14px;
    color: #5972F4;
    margin: 0px;
}

.revenue_table_header {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
}

.revenue_table_content {
    font-size: 14px;
    color: #666666;
    font-weight: 600;
}

.revenue_table_body {
    padding: 0 5px;
    border: 1px solid #c1baba;
    border-radius: 2px;
    height: 45px;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}

.revenue_payment_type_img {
    width: 20px;
    height: 20px;
    margin-left: 25px;
}

.revenue_table_body div {
    font-size: 14px;
    color: #707070;
}

.revenue_grid {
    padding: 0 5px;
}

.revenue_table_header span {
    font-size: 10px;
    font-weight: 500;
}

.revenue_ans_div {
    margin-top: 16px;
}

.revenue_eye_img {
    margin-left: 4px;
    width: 20px;
    height: 15px;
}

.revenue_modal_title {
    font-size: 18px;
    color: #83AF40;
}

.modal-header .btn-close {
    font-size: 13px;
}

.modal-header {
    border-bottom: 1px solid #c9c8c8;
    padding: 8px;
}

.revenue_modal_img {
    text-align: center;
}

.revenue_modal_img img {
    width: 70px;
    height: 70px;
    border-radius: 50px;
}

.revenue_modal_img div {
    font-size: 16px;
    color: #83AF40;
}

.revenue_hr_line {
    border-style: dashed;
    width: 82%;
    margin: auto;
    margin-top: 12px;
}

.revenue_modal_ttl {
    font-size: 12px;
    color: #000000CC;
    font-weight: 600;
    margin-bottom: 6px;
}

.revenue_modal_ttlDtl {
    font-size: 12px;
    color: #707070;
}

.revenue_modal_ttlDtl img {
    width: 20px;
    height: 20px;
    margin-left: 4px;
}

.revenue_modal_bodyGrid {
    padding: 5px 0;
}

.revenue_grid_container {
    width: 95%;
    margin: auto;
    margin-top: 14px;
}

.modal-content {
    border-radius: 1px;
}

.revenue_modal_ttlDtl span {
    margin-left: 3px;
}

.revenue_detials.ant-card .ant-card-body {
    padding: 30px 0px 30px 0px;
    border-radius: 0 0 8px 8px
}

.settlement_income_total {
    width: 101px;
    height: 33px;
    background: #82AE3F;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    border-radius: 65px;
}

.filter_submit {
    width: 101px;
    height: 33px;
    background: #82AE3F;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    border-radius: 65px;
}

.total_income {
    display: flex;
    justify-content: end;
}

.settlement_income_sub_total {
    /* width: 150px; */
    height: 35px;
    color: #83AF40;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 5px;
    margin: 0px 5px 0px 5px;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
    border: 1px solid #83AF40;
}

.settlement_income_sub_total span {
    font-size: 10px;
    display: contents;
}

.settlement_income_sub_total_fees {
    width: 80px;
    height: 33px;
    color: #83AF40;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    border-radius: 65px;
    margin: 0px 5px 0px 5px;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
    border: 1px solid #83AF40;
}

.revenue_toggle {
    border-radius: 65px;
    height: 40px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.settlement_toggle {
    border-radius: 65px;
    height: 40px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.revenue_toggle_head {
    display: flex;
    background: white;
    border-radius: 65px;
    box-shadow: 0px 3px 6px #00000029;
}

.revenuelist_subhead_card {
    background: #F8F8F8;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px 5px 0px 0px;
}

.revenue_sub_heading_title {
    background: #F8F8F8;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px 5px 0px 0px;
}

.revenue_sub_heading_title_settlement {
    background: #F8F8F8;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px 5px 0px 0px;
    margin-top: 30px;
}

.revenuelist_subhead_card .ant-card-body {
    padding: 10px;
}

.revenue_sub_heading_title .ant-card-body {
    padding: 10px;
}
.revenue_sub_heading_title_settlement .ant-card-body {
    padding: 10px;
}

.revenue_content .ant-card-body {
    padding: 10px;
    height: 85px;
    display: flex;
    align-items: center;
}

.revenue_content {
    background: #F8F8F8;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px 5px 0px 0px;
    margin: 10px 0px 0px 0px;
}

.settlement_content .ant-card-body {
    padding: 10px;
    height: 85px;
    display: flex;
    align-items: center;
}

.settlement_content {
    background: #F8F8F8;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px 5px 0px 0px;
    margin: 10px 0px 10px 0px;
}

.revenue_date {
    display: flex;
    align-items: center;
    color: #83AF40;
    font-size: 20px;
}

.revenue_select_range {
    display: flex;
    justify-content: end;
    align-items: center;
}

.select_range_revenue {
    margin-right: 10px;
}

.calendar_revenue_icon {
    position: absolute;
    margin-right: 10px;
}

.search_revenue .MuiInputBase-inputSizeSmall,
.MuiSvgIcon-fontSizeMedium {
    height: 20px;
    width: 80px;
}

.revenue_select_range .MuiInputBase-inputSizeSmall,
.MuiSvgIcon-fontSizeMedium {
    height: 20px;
}

.revenue_header_icons {
    display: flex;
    align-items: center;
}

.dialog_revenue_header {
    display: flex;
    padding: 20px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 3px;
}

.dialog_revenue_header .dialog_revenue_title {
    color: #83AF40;
    font-size: 17px;
    font-family: TitilliumWeb-Regular;
}

.dialog_revenue_header img {
    width: 18px;
    cursor: pointer;
}

.download_button {
    display: flex;
    justify-content: end;
    margin-top: 10px;
}

.revenue_select_filter .css-1ahrjp1-MuiFormControl-root {
    margin: 10px 0px;
}

.download_icon_recepit {
    display: flex;
    /* align-items: end; */
    justify-content: end;
}

.revenue_label_top {
    margin-top: 30px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.insurance_details_revenue .ant-card .ant-card-body {
    padding: 3px 3px 10px 0px;
}

.insurance_incentive .ant-card .ant-card-body {
    padding: 3px 3px 15px 15px;
}

.insurance_details_revenue_card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #707070;
    border-radius: 29px 0px 29px 29px;
}

.insurance_details_revenue_insentive {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #707070;
    border-radius: 17px 0px 17px 17px;
    margin: 0px 15px;
}

.search_revenue .css-spo6rv-MuiPaper-root {
box-shadow: none;
border-radius: 0;
}