.remarks_content {
    padding: 40px 60px;
}

.remarks_field {
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E8E9EC;
    border-radius: 3px;
}

.remarks_field .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 15px;
}

.remarks_content_img {
    display: flex;
    justify-content: center;
    /* padding-top: 30px; */
}

.remark_view {
    margin-top: 60px !important;
}

.remarks_img {
    width: 60px;
    height: 60px;
    margin: 10px;
}

.remarks_images {
    margin: 0px 20px;
    text-align: center;
}

.remarks_submit {
    text-align: center;
    /* padding-top: 40px; */
}

/* =================== */

.boxContainer {
    display: flex;
    justify-content: space-between;
    height: 31px;
    --boxSize: 3px;
    --gutter: 4px;
    /* width: calc((var(--boxSize) + var(--gutter)) * 5); */
    width: 300px
}

.box {
    transform: scaleY(.4);
    height: 100%;
    width: var(--boxSize);
    background: var(--app-theme);
    animation-duration: 1.2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    border-radius: 8px;
}

.box1 {
    animation-name: quiet;
}

.box2 {
    animation-name: normal;
}

.box3 {
    animation-name: quiet;
}

.box4 {
    animation-name: loud;
}

.box5 {
    animation-name: quiet;
}

.box6 {
    animation-name: normal;
}

.box7 {
    animation-name: loud;
}

.box8 {
    animation-name: quiet;
}

.box9 {
    animation-name: loud;
}

.box10 {
    animation-name: quiet;
}

.box11 {
    animation-name: normal;
}

.box12 {
    animation-name: loud;
}

.box13 {
    animation-name: normal;
}

.box14 {
    animation-name: quiet;
}

.box15 {
    animation-name: loud;
}

.box16 {
    animation-name: normal;
}

.box17 {
    animation-name: quiet;
}

.box18 {
    animation-name: loud;
}

.box19 {
    animation-name: normal;
}

.box20 {
    animation-name: quiet;
}

@keyframes Loading {

    0%,
    100% {
        height: 5px;

    }

    50% {
        height: 25px;
    }
}

@keyframes quiet {
    25% {
        transform: scaleY(.6);
    }

    50% {
        transform: scaleY(.4);
    }

    75% {
        transform: scaleY(.8);
    }
}

@keyframes normal {
    25% {
        transform: scaleY(1);
    }

    50% {
        transform: scaleY(.4);
    }

    75% {
        transform: scaleY(.6);
    }
}

@keyframes loud {
    25% {
        transform: scaleY(1);
    }

    50% {
        transform: scaleY(.4);
    }

    75% {
        transform: scaleY(1.2);
    }
}

/* =================== */

.close_icons {
    font-size: 12px;
    margin: 2px 5px;
    color: red;
    cursor: pointer;
    font-weight: bold;
}

.button_texts {
    /* width: fit-content;
    border-radius: 25px;
    border: 1px solid #707070;
    cursor: pointer;
    padding: 0px 10px;
    position: relative;
    bottom: 110px; */
    width: fit-content;
    border-radius: 25px;
    border: 1px solid #707070;
    cursor: pointer;
    padding: 0px 10px;
    height: 23px;
}

.button_texts p {
    font-size: 10px;
    margin: 3px 0;
    text-overflow: ellipsis;
    width: 220px;
    overflow: hidden !important;
    white-space: nowrap;
}

.remarks_img_show {
    /* width: 100px;
    height: 100px;
    margin: 10px;
    position: relative;
    bottom: 138px;
    top: 30px; */
    width: 100px;
    height: 100px;
    margin: 10px;
}

.contents {
    color: #000000;
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
    font-family: Roboto-Regular
}

.remark_view {
    height: calc(100vh - 140px) !important;
}

.support_view {
    display: flex;
}

  
._2fG9h{
    padding: 0px 0px 40px 0px !important;
    
  }

  ._dt3-T {
   min-height: 300px !important;
  }

  ._1Yplu{
    top: calc(100% + 5px);
    position: absolute;
    /* left: 64%; */
    left: calc(100% - 150px);
    margin: 0px !important;
  }
  ._1ceqH ._1YOWG ._eV_dK{
    text-align: center;
  }

  ._1ceqH ._1Yplu ._1Pz2d{
    border: 0;
    outline: 0;
    padding: 4px 8px !important;
    border-radius: 20px !important;
    background: #185fec;
    color: #fff;
    cursor: pointer;
    border: 1px solid #185fec;
    transition: .3s ease all;
    font-size: 17px !important;
  }
  ._1ceqH ._1Yplu ._2gd2_{
    background: #fff;
    color: #185fec;
    margin-left: 5px !important;
    padding: 4px 14px !important;
  }
  ._1ceqH ._1Yplu{
    padding-top: 10px;
  }
   .css-bdhsul-MuiTypography-root-MuiDialogTitle-root{
    padding: 0px !important;
    padding-bottom: 10px !important;
  }
  .css-ohyacs{
    padding: 0px !important;
    padding-bottom: 10px !important;
  }